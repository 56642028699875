<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store">
        <template v-slot:applied>
            <ui-avatar family="lists" :name="value.name" class="w-4 h-4 drop-shadow-sm shrink-0 mr-1" v-if="! value.length || value.length === 1"></ui-avatar>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} {{filter.name}}s
            </span>
            <span class="font-medium" v-else>
                {{value.length ? value[0].name : value.name}}
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <div class="relative w-full text-gray-900 px-4">
                    <div class="absolute inset-y-0 left-6 flex items-center pointer-events-none">
                        <ui-icon name="search" class="text-sm"></ui-icon>
                    </div>
                    <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-8 py-2 rounded-sm bg-gray-100 placeholder-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm shadow-sm" v-model="search">
                </div>

                <ui-dropdown-separator class="my-3"></ui-dropdown-separator>

                <div class="max-h-80 overflow-y-auto" v-if="foundLists.length">
                    <ui-dropdown-link :checkbox="filter.multiple" :selected="!!value && value.id === list.id" @click="store.addFilter(filter.id, list)" icon="navigation.sub.channel-lists" :key="list.id" v-for="list in foundLists">
                        <template v-slot:icon>
                            <div class="w-4 flex justify-center items-center text-center">
                                <ui-avatar family="lists" :name="list.name" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                            </div>
                        </template>

                        {{list.name}}
                    </ui-dropdown-link>
                </div>

                <div class="h-full flex flex-col justify-center items-center text-center text-gray-600 h-full text-sm p-4" v-else>
                    <ui-icon name="search" class="text-2xl text-gray-500 mb-1"></ui-icon>
                    <p class="mb-1">No results found.</p>
                    <p class="text-xs">Please try a different search query.</p>
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

<!--                <ui-button @click="apply" color="blue" size="sm">Apply</ui-button>-->
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyChannelListsStore from '@/stores/me/channel-lists'

import textSearch from '@/helpers/text-search'
import { mapState } from 'pinia'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        search: '',
        localItems: []
    }),

    computed: {
        ...mapState(useMyChannelListsStore, [ 'items' ]),

        value() {
            return this.store.getFilterValue(this.filter.id)
        },

        foundLists() {
            return this.search
                ? textSearch(this.search, this.items, t => t.name)
                : this.items
        }
    },

    methods: {
        // toggleLocalItem(item) {
        //     this.localItems.some(v => v.id === item.id) ? this.localItems.splice(this.localItems.findIndex(v => v.id === item.id), 1) : this.localItems.push(item)
        // },
        //
        // reset() {
        //     this.search = ''
        //
        //     this.localItems = this.store.getFilterValue(this.filter.id) ? [...this.store.getFilterValue(this.filter.id)] : []
        // },
        //
        // apply() {
        //     this.localItems.length ? this.store.addFilter(this.filter.id, this.localItems) : this.store.removeFilter(this.filter.id)
        // }
    }
}
</script>
