import useMyNotificationsStore from '@/stores/me/notifications'

import api from '@/api'

export function isLanguageTranslatable(code) {
    code = code instanceof Array ? code : [ code ]

    return code.some(c => ! ['00', null, 'und', 'en'].includes(c))
}

export function isLanguageRTL(code) {
    code = code instanceof Array ? code : [ code ]

    return code.every(c => ['ar', 'arc', 'ha', 'khw', 'ks', 'ps', 'yi', 'az', 'dv', 'he', 'ku', 'ckb', 'fa', 'ur'].includes(c))
}

export function translate(item, params = {}, beforeFn = null, afterFn = null) {
    item.translation = { isLoading: true, text: undefined }

    if (beforeFn) { beforeFn() }

    return api.route('monitor content translate', { id: params.id ?? item.id })
        .json(params)
        .post()
        .error(422, e => {
            if (e.json.code === 'invalid_translation_language_pair_error') {
                return useMyNotificationsStore().pushToast({
                    title: e.json.title,
                    body: e.json.message,
                    level: 'error'
                })
            }

            return e
        })
        .text(text => item.translation.text = text)
        .finally(() => {
            item.translation.isLoading = false

            if (afterFn) { afterFn() }
        })
}

export default translate
