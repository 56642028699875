<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg">
        <div class="flex mb-4 border-b border-gray-200">
            <a @click.prevent="selectedType = type.id" href="#" class="flex-1 block font-medium px-4 py-4 hover:text-blue-600 group -mb-px" :class="{'border-b border-blue-500 text-blue-600': selectedType == type.id}" :key="type.id" v-for="type in types">
                <div class="font-medium">{{ type.name }}</div>
                <div class="text-xs font-normal group-hover:text-blue-500" :class="selectedType == type.id ? 'text-blue-500' : 'text-gray-800'">{{ type.description }}</div>
            </a>
            <router-link :to="{ name: 'connections', params: { ids: $page.feature.id } }" class="flex-1 flex items-center px-4 py-4 hover:text-blue-600 group">
                <ui-icon name="share-2" class="mr-2"></ui-icon>
                <span class="font-medium">Explore</span>
                <ui-icon name="arrow-right-circle" class="ml-auto"></ui-icon>
            </router-link>
        </div>
        
        <div>
            <connections-section title="Connected Channels" :connections="connectedChannels" :connections-store="featuresFeatureConnectionsStore" v-if="selectedType == 'connected-channels'"></connections-section>
            <connections-section title="Coexisting Features" :connections="coexistingFeatures" :connections-store="featuresFeatureConnectionsStore" v-if="selectedType == 'coexisting-features'"></connections-section>
        </div>    
    </div>
</template>

<script>
import ConnectionsSection from '@/components/channels/channel/partials/connections-section'

import useConnectionsStore from '@/stores/features/feature/connections'

import { mapState, mapStores, mapWritableState } from 'pinia'

export default {
    components: { ConnectionsSection },

    data: () => ({
        types: [
            { id: 'connected-channels', name: 'Connected Channels', description: 'Channels connected to this feature directly or via published content.' },
            { id: 'coexisting-features', name: 'Coexisting Features', description: 'Features co-existing on the same content or channels.' }
        ]
    }),

    computed: {
        ...mapState(useConnectionsStore, [
            'connectedChannels', 'coexistingFeatures'
        ]),
        
        ...mapWritableState(useConnectionsStore, [
            'selectedType'
        ]),

        ...mapStores(useConnectionsStore)
    }
}
</script>
