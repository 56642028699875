<template>
    <div class="w-full bg-white shadow-sm mb-4 sticky top-0 z-40 border-l border-gray-200 pt-2.5" :class="{ 'pb-2.5': ! $slots.navigation }">
        <div class="px-8 mx-auto" :class="{ 'max-w-7xl': slim, 'max-w-12xl': ! slim }" v-if="title">
            <div class="flex items-center justify-between">
                <div class="flex items-center flex-1">
                    <router-link :to="back" class="text-gray-800 hover:text-gray-900" v-if="back">
                        <ui-icon name="back" class="text-xl mr-4"></ui-icon>
                    </router-link>

                    <slot name="title">
                        <div class="relative w-8 h-8 shrink-0 mr-3" v-if="avatar || avatarType">
                            <ui-avatar :item="{ family: avatarType, name: title, avatar }" class="w-8 h-8"></ui-avatar>
                            <ui-icon :name="badge" class="absolute -right-px -bottom-px text-lg drop-shadow" style="--tw-drop-shadow: drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(1px 0 0 white);" v-if="badge"></ui-icon>
                        </div>

                        <ui-icon :name="icon" class="shrink-0 mr-2 text-3xl" v-else-if="icon"></ui-icon>

                        <div class="flex-1 flex items-center gap-1">
                                <div>
                                    <h1 class="text-3xl font-semibold leading-none">{{ title }}</h1>
                                    <div class="text-gray-800 text-xs leading-none mt-1 flex space-x-1" v-if="subtitle || $slots.subtitle">
                                        <slot name="subtitle">{{ subtitle }}</slot>
                                    </div>
                                </div>
                            <slot name="actions-start"></slot>
                        </div>
                    </slot>
                </div>

                <div class="flex items-center space-x-4">
                    <slot name="actions"></slot>
                    <slot name="actions-end"></slot>
                </div>
            </div>
        </div>
        <div class="mx-auto" :class="{ 'max-w-7xl': slim, 'max-w-12xl': ! slim }" v-if="$slots.subsection">
            <slot name="subsection"></slot>
        </div>
        <div class="px-8 pt-3.5 mx-auto space-x-8 flex mx-auto" :class="{ 'max-w-7xl': slim, 'max-w-12xl': ! slim }" v-if="$slots.navigation">
            <slot name="navigation"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'avatar', 'avatarType', 'back', 'badge', 'icon', 'slim', 'subtitle', 'title' ]
}
</script>
