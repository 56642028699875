import useModalsExportOptionsStore from '@/stores/modals/export-options'

import api from '@/api'
import { useModal } from '@/helpers'
import { channels } from '@/helpers/api-three-tables'

import useMyNotificationsStore from '@/stores/me/notifications'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export const useMyMonitoredChannelsStore = defineStore({
    id: 'my-monitored-channels',

    state: () => ({
        items: [],
        paginated: true,

        searchQuery: '',
        filter: '',

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,
        lastReloadTime: +new Date()
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = this.channelsQuery()
                .get()
                .then(channels => {
                    this.items = channels

                    this.isLoading = false
                    this.isInitialized = true

                    this.loadingPromise = null
                })
        },

        async reload() {
            this.lastReloadTime = +new Date()
            return this.load(true)
        },

        async loadMore(infiniteScroll) {
            let lastItem = this.items[this.items.length - 1]

            if (! lastItem) return infiniteScroll.complete()

            this.channelsQuery()
                .after({ id: lastItem.id, family: lastItem.family })
                .get()
                .then(channels => {
                    if (channels.length) {
                        this.items = [ ...this.items, ...channels ]
                        infiniteScroll.loaded()
                    } else {
                        infiniteScroll.complete()
                    }
                })
        },

        async all() {
            await this.load()
            return this.items
        },

        setFilter(filter) {
            this.filter = filter
            this.reload()
        },

        search: debounce(function (query) {
            this.searchQuery = query
            this.reload()
        }, 250),

        export() {
            useModalsExportOptionsStore().open({ query: this.channelsQuery() })
        },

        channelsQuery() {
            return channels()
                .filters('list', 'monitored')
                .filters('name', { query: this.searchQuery })
                .sorting('name-asc')
        },

        async enableMonitoring(channel) {
            channel.monitoringRequested = true

            await api.route('me channels monitoring store', { id: channel.id })
                .post()
                .error(400, () => useModal().show('channels-my-channels-resource-unavailable'))
                .res(() => {
                    useMyNotificationsStore().pushToast({
                        title: 'Adding your channel...',
                        body: 'This might take a few minutes, feel free to continue using the app.',
                        expires: 10
                    })
                })
        },

        disableMonitoring(channel) {
            api.route('me channels monitoring delete', { id: channel.id })
                .delete()
        },

        isMonitored(channel) {
            return !! channel.lists?.find(l => l.type == 'monitored')
        },

        toggleMonitoringOption(channel, option) {
            return channel.monitoringOptions.includes(option)
                ? this.disableMonitoringOption(channel, option)
                : this.enableMonitoringOption(channel, option)
        },

        enableMonitoringOption(chanel, option) {
            return api.route('me channels options store', { family: chanel.family, id: chanel.id, option })
                .post()
                .error(400, () => useModal().show('channels-my-channels-options-resource-unavailable'))
                .res(() => chanel.monitoringOptions.push(option))
        },

        disableMonitoringOption(channel, option) {
            return api.route('me channels options delete', { family: channel.family, id: channel.id, option })
                .delete()
                .res(() => channel.monitoringOptions = channel.monitoringOptions.filter(o => o != option))
        }
    }
})

export default useMyMonitoredChannelsStore
