import useMyStore from '@/stores/me/my'
import useMyNotificationsStore from '@/stores/me/notifications'

import { defineForm } from '@/stores/reusable/form'

import { default as api, asyncResource, content } from '@/api'
import { useModal } from '@/helpers'

export const useContentModalsSmartTagStore = defineForm({
    id: 'contentModalsSmartTag',

    inputs: () => ({
        question: null,
        yesTag: null,
        noTag: null,
        context: null
    }),

    submitRequest() {
        return api.route('monitor content smart-tags store')
            .json({
                filters: this.filters.toPerspective(),
                sorting: this.sorting.api,
                question: this.inputs.question,
                yesTag: this.inputs.yesTag?.id,
                noTag: this.inputs.noTag?.id,
                context: this.inputs.context,
                preview: ! this.previewTask
            })
            .post()
    },

    async onResponse(res) {
        if (! this.previewTask) {
            this.previewTask = useMyNotificationsStore().resolveTask(res.taskId)
            useMyNotificationsStore().onTaskCompletion(res.taskId, () => {
                if (this.previewTask.payload.yesResults.length) this.yesContent.fetch(this)
                if (this.previewTask.payload.noResults.length) this.noContent.fetch(this)
            })
        } else {
            useModal().hide('content-smart-tag')
        }
    },
    
    state: () => ({
        filters: null,
        sorting: null,
        itemsCount: null,
        previewTask: null,

        yesContent: asyncResource({
            method: 'post',
            request: (api, store) => content().ids(store.previewTask.payload.yesResults).toRequest()
        }),
        noContent: asyncResource({
            method: 'post',
            request: (api, store) => content().ids(store.previewTask.payload.noResults).toRequest()
        })
    }),

    getters: {
        hasBudgetAvailable: store => store.itemsCount
            && useMyStore().budgets?.smartTags
            && useMyStore().budgets.smartTags.limit - useMyStore().budgets.smartTags.usage - store.itemsCount >= 0
    },

    actions: {
        open(filters, sorting) {
            this.reset()
            
            this.filters = filters
            this.sorting = sorting
            this.previewTask = null

            this.yesContent.reset()
            this.noContent.reset()
            
            useMyStore().loadBudgets()
            this.loadItemsCount()

            useModal().show('content-smart-tag')
        },

        back() {
            this.previewTask = null

            this.yesContent.reset()
            this.noContent.reset()
        },

        loadItemsCount() {
            content().filters(this.filters.toPerspective()).count(count => this.itemsCount = count)
        }
    }
})

export default useContentModalsSmartTagStore
