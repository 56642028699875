<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="reset" :keep-open="keepOpen">
        <template v-slot:applied>
            <ui-icon :name="filter.icon" class="mr-1"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} {{filter.name}}
            </span>
            <span class="font-medium max-w-sm truncate" v-else>
                {{value.length ? value[0].name : value.name}}
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <p v-if="! selectedTargetsToInclude.length && ! selectedTargetsToExclude.length" class="text-xs text-center my-3">Showing content from all Targets.</p>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <div v-if="selectedTargetsToInclude.length" class="text-xs font-medium text-gray-700 px-4 mb-1">
                    Show only content from
                </div>

                <ui-dropdown-link @click="deselectTarget(target)" keep-open icon="none" :key="`${target.family}-${target.id}`" v-for="target in selectedTargetsToInclude">
                    <template v-slot:icon>
                        <div class="w-4 flex justify-center items-center text-center">
                            <ui-avatar :item="target" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                        </div>
                    </template>
                    <template v-slot:default>
                        <div class="flex items-center group">
                            {{target.name}}
                            <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                        </div>
                    </template>
                </ui-dropdown-link>

                <ui-dropdown-link v-if="selectedTargetsToInclude.length" icon="plus-circle" @click="selectTargetToInclude" keep-open>
                    Add Target...
                </ui-dropdown-link>
                <ui-dropdown-link v-else @click="selectTargetToInclude" keep-open>
                    Show only content from...
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <div v-if="selectedTargetsToExclude.length" class="text-xs font-medium text-gray-700 px-4 mb-1">
                    Exclude content from
                </div>

                <div class="max-h-80 overflow-y-auto">
                    <ui-dropdown-link @click="deselectTarget(target)" keep-open icon="none" :key="`${target.family}-${target.id}`" v-for="target in selectedTargetsToExclude">
                        <template v-slot:icon>
                            <div class="w-4 flex justify-center items-center text-center">
                                <ui-avatar :item="target" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                            </div>
                        </template>
                        <template v-slot:default>
                            <div class="flex items-center group">
                                {{target.name}}
                                <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                            </div>
                        </template>
                    </ui-dropdown-link>
                </div>

                <ui-dropdown-link v-if="selectedTargetsToExclude.length" icon="plus-circle" @click="selectTargetToExclude" keep-open>
                    Add Target...
                </ui-dropdown-link>
                <ui-dropdown-link v-else @click="selectTargetToExclude" keep-open>
                    Exclude content from...
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers/sorting'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        selectedTargets: [],

        keepOpen: false
    }),

    computed: {
        value() {
            return this.store.getFilterValue(this.filter.id)
        },

        selectedTargetsToInclude() {
            return sortAlphabetically(this.selectedTargets.filter(val => ! val.exclude))
        },

        selectedTargetsToExclude() {
            return sortAlphabetically(this.selectedTargets.filter(val => val.exclude))
        }
    },

    methods: {
        selectTargetToInclude() {
            this.selectTarget()
        },

        selectTargetToExclude() {
            this.selectTarget(true)
        },

        selectTarget(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'targets', 'targetLists' ],
                limit: 50,
                onSelect: result => {
                    this.selectedTargets.push({
                        id: result.id[0] == 'G' || result.id[0] == 'S' ? result.id.substring(1) : result.id,
                        type: result.resultType == 'list' ? 'list' : 'channel',
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                },
                initialResults: [
                    ...useMyChannelListsStore().items.filter(item => item.pinned || item.recent).map(item => ({
                        id: item.id,
                        resultType: 'list',
                        title: item.name
                    })),
                    ...useMyBookmarksStore().items.map(item => ({
                        id: item.id,
                        resultType: 'channel',
                        type: item.type,
                        title: item.name,
                        avatar: item.avatar,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    }))
                ]
            })
        },

        deselectTarget(target) {
            this.selectedTargets = this.selectedTargets.filter(f => f.id != target.id || f.type != target.type)
        },

        reset() {
            this.selectedTargets = (this.store.filter(this.filter.id).value || []).map(mention => ({
                ...mention
            }))
        },

        apply() {
            if (this.selectedTargets.length) {
                this.store.filter(this.filter.id).set(this.selectedTargets.map(mention => ({
                    ...mention
                })))
            } else {
                this.store.filter(this.filter.id).remove()
            }
        }
    },

    watch: {
        search(val) { this.fetchResults(val) }
    }
}
</script>
