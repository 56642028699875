<template>
    <ui-dropdown @opened="opened" align="right" width="w-auto" ref="dropdown">
        <template v-slot:trigger>
            <ui-button :icon="mySubscriptions.muted ? 'bell-off' : 'bell'" size="sm">
                Notifications
                <ui-icon name="chevron-down" class="ml-1"></ui-icon>
            </ui-button>
        </template>

        <template v-slot:content>
            <div @click.stop>
                <template v-if="controls == 'workspace-email'">
                    <div class="w-48">
                        <div class="flex items-center justify-between p-2">
                            <span class="font-semibold text-sm text-gray-700">
                                <ui-icon name="email" class="mr-1"></ui-icon>
                                Email
                            </span>

                            <a @click.prevent="controls = 'workspace'" href="#" class="flex items-center justify-center text-gray-600 hover:text-gray-700 focus:outline-none focus:text-gray-600 rounded-full bg-gray-50 hover:bg-gray-200 transition w-5 h-5 text-xs">
                                <ui-icon name="arrow-left"></ui-icon>
                            </a>
                        </div>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="users" :selected="workspaceSubscriptions.types.includes('email')" @click="store.toggleSubscription('email', 'workspace')" keep-open>
                            Notify workspace
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <div class="text-sm text-gray-700 p-2">
                            Additional recipients
                        </div>

                        <template v-if="workspaceSubscriptions.configuration.email && workspaceSubscriptions.configuration.email.additionalRecipients">
                            <ui-dropdown-link :key="index" v-for="recipient, index in workspaceSubscriptions.configuration.email.additionalRecipients" keep-open>
                                <div class="truncate">
                                    {{recipient}}
                                </div>
                                <div class="ml-auto flex shrink-0 justify-center items-center space-x-2">
                                    <a @click.prevent="emailRemoveAdditionalRecipient(recipient)" href="#" class="flex items-center justify-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600 rounded-full bg-gray-200 hover:bg-gray-300 transition w-5 h-5 text-xs">
                                        <ui-icon name="remove"></ui-icon>
                                    </a>
                                </div>
                            </ui-dropdown-link>
                        </template>

                        <ui-dropdown-link icon="plus" submenu submenu-width="w-72" keep-open>
                            Add recipient

                            <template v-slot:submenu>
                                <div class="p-2">
                                    <label for="notifications-email-additionalRecipients-email" class="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>

                                    <ui-input id="notifications-email-additionalRecipients-email" v-model="emailAdditionalRecipient"></ui-input>

                                    <div class="flex justify-end space-x-3 mt-4 mb-1">
                                        <ui-button color="blue" :action="emailAddAdditionalRecipient">
                                            Add
                                        </ui-button>
                                    </div>
                                </div>
                            </template>
                        </ui-dropdown-link>
                    </div>
                </template>

                <template v-else-if="controls == 'workspace-mattermost'">
                    <div class="w-96">
                        <div class="flex items-center justify-between p-2">
                            <span class="font-semibold text-sm text-gray-700">
                                <ui-icon name="mattermost" class="mr-1"></ui-icon>
                                Mattermost
                            </span>

                            <a @click.prevent="controls = 'workspace'" href="#" class="flex items-center justify-center text-gray-600 hover:text-gray-700 focus:outline-none focus:text-gray-600 rounded-full bg-gray-50 hover:bg-gray-200 transition w-5 h-5 text-xs">
                                <ui-icon name="arrow-left"></ui-icon>
                            </a>
                        </div>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <div class="p-2">
                            <label for="notifications-mattermost-webhookUrl" class="block text-sm font-medium text-gray-700">
                                Webhook URL
                            </label>

                            <ui-input id="notifications-mattermost-webhookUrl" v-model="mattermostWebhookUrl"></ui-input>

                            <div class="flex space-x-3 mt-3">
                                <div>
                                    <label for="notifications-mattermost-channel" class="block text-sm font-medium text-gray-700">
                                        Channel
                                    </label>

                                    <ui-input id="notifications-mattermost-channel" placeholder="(optional)" v-model="mattermostChannel"></ui-input>
                                </div>

                                <div>
                                    <label for="notifications-mattermost-username" class="block text-sm font-medium text-gray-700">
                                        Username
                                    </label>

                                    <ui-input id="notifications-mattermost-username" placeholder="(optional)" v-model="mattermostUsername"></ui-input>
                                </div>
                            </div>

                            <div class="flex justify-end space-x-3 mt-4 mb-1">
                                <ui-button color="red" @click="mattermostDisable">Disable</ui-button>
                                <ui-button color="blue" @click="mattermostEnable">Apply</ui-button>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="controls == 'workspace'">
                    <div class="w-48">
                        <ui-dropdown-link icon="users" @click="store.toggleSubscription('in-app', 'workspace')" :selected="workspaceSubscriptions.types.includes('in-app')" keep-open>
                            Notify Workspace
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <template v-if="workspaceSubscriptions.types.includes('in-app')">
                            <ui-dropdown-link icon="bell" :selected="workspaceSubscriptions.types.includes('in-app', 'workspace')" toggleable disabled keep-open>
                                In-app
                            </ui-dropdown-link>

                            <ui-dropdown-link icon="email" @click="controls = 'workspace-email'" :selected="workspaceSubscriptions.types.includes('email')" toggleable keep-open>
                                Email...
                            </ui-dropdown-link>

                            <ui-dropdown-link icon="mattermost" @click="controls = 'workspace-mattermost'" :selected="workspaceSubscriptions.types.includes('mattermost')" toggleable keep-open>
                                Mattermost...
                            </ui-dropdown-link>

                            <ui-dropdown-separator></ui-dropdown-separator>
                        </template>

                        <div class="text-center text-xs  text-gray-700 py-1" v-if="workspaceSubscriptions.muted">
                            <div>
                                <template v-if="workspaceSubscriptions.muted === true">
                                    Notifications muted indefinitely
                                </template>

                                <template v-else>
                                    Notifications muted until<br>
                                    <span class="font-semibold">
                                        {{ $dateTime(workspaceSubscriptions.muted) }}
                                    </span>
                                </template>
                            </div>

                            <a @click.prevent="unmuteSubscriptions('workspace')" href="#" class="text-gray-600 hover:text-gray-700">
                                Unmute
                            </a>
                        </div>

                        <ui-dropdown-link icon="bell-off" submenu submenu-width="w-24" :selected="false" v-else>
                            Mute notifications...

                            <template v-slot:submenu>
                                <ui-dropdown-link @click="muteSubscriptions(15, 'workspace')" keep-open>
                                    15 min
                                </ui-dropdown-link>

                                <ui-dropdown-link @click="muteSubscriptions(60, 'workspace')" keep-open>
                                    1 hour
                                </ui-dropdown-link>

                                <ui-dropdown-link @click="muteSubscriptions(60 * 24, 'workspace')" keep-open>
                                    1 day
                                </ui-dropdown-link>

                                <ui-dropdown-link @click="muteSubscriptions(true, 'workspace')" keep-open>
                                    Indefinite
                                </ui-dropdown-link>
                            </template>
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="user" @click="controls = null" :selected="hasSubscriptions" keep-open>
                            My notifications...
                        </ui-dropdown-link>
                    </div>
                </template>

                <template v-else>
                    <div class="w-48">
                        <ui-dropdown-link icon="user" @click="store.toggleSubscription('in-app')" :selected="mySubscriptions.types.includes('in-app')" keep-open>
                            Notify Me
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <template v-if="mySubscriptions.types.includes('in-app')">
                            <ui-dropdown-link icon="bell" :selected="mySubscriptions.types.includes('in-app')" toggleable disabled keep-open>
                                In-app
                            </ui-dropdown-link>

                            <ui-dropdown-link icon="email" @click="store.toggleSubscription('email')" :selected="mySubscriptions.types.includes('email')" toggleable keep-open>
                                Email
                            </ui-dropdown-link>

                            <ui-dropdown-separator></ui-dropdown-separator>
                        </template>

                        <div class="text-center text-xs text-gray-700 py-1" v-if="mySubscriptions.muted">
                            <div>
                                <template v-if="mySubscriptions.muted === true">
                                    Notifications muted indefinitely
                                </template>

                                <template v-else>
                                    Notifications muted until<br>
                                    <span class="font-semibold">
                                        {{ $dateTime(mySubscriptions.muted) }}
                                    </span>
                                </template>
                            </div>

                            <a @click.prevent="unmuteSubscriptions()" href="#" class="text-gray-600 hover:text-gray-700">
                                Unmute
                            </a>
                        </div>

                        <ui-dropdown-link icon="bell-off" submenu submenu-width="w-24" :selected="false" v-else>
                            Mute notifications...

                            <template v-slot:submenu>
                                <ui-dropdown-link @click="muteSubscriptions(15)" keep-open>
                                    15 min
                                </ui-dropdown-link>

                                <ui-dropdown-link @click="muteSubscriptions(60)" keep-open>
                                    1 hour
                                </ui-dropdown-link>

                                <ui-dropdown-link @click="muteSubscriptions(60 * 24)" keep-open>
                                    1 day
                                </ui-dropdown-link>

                                <ui-dropdown-link @click="muteSubscriptions(true)" keep-open>
                                    Indefinite
                                </ui-dropdown-link>
                            </template>
                        </ui-dropdown-link>

                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="users" @click="controls = 'workspace'" :selected="hasWorkspaceSubscriptions" keep-open>
                            Workspace notifications...
                        </ui-dropdown-link>
                    </div>
                </template>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
import { zonedToUTC } from '@/helpers/datetime'

import { addMinutes } from 'date-fns'

export default {
    data: () => ({
        controls: null,

        emailAdditionalRecipient: '',

        mattermostWebhookUrl: '',
        mattermostChannel: '',
        mattermostUsername: ''
    }),

    computed: {
        mySubscriptions() {
            return this.store.appliedPerspective.subscriptions.me
        },

        workspaceSubscriptions() {
            return this.store.appliedPerspective.subscriptions.workspace
        },

        hasSubscriptions() {
            return !! (this.mySubscriptions.types.length || this.workspaceSubscriptions.types.length)
        },

        hasWorkspaceSubscriptions() {
            return !! this.workspaceSubscriptions.types.length
        }
    },

    methods: {
        opened() {
            this.controls = null

            this.emailAdditionalRecipient = ''

            this.mattermostWebhookUrl = this.workspaceSubscriptions.configuration?.mattermost?.webhookUrl
            this.mattermostChannel = this.workspaceSubscriptions.configuration?.mattermost?.channel
            this.mattermostUsername = this.workspaceSubscriptions.configuration?.mattermost?.username
        },

        emailAddAdditionalRecipient() {
            let additionalRecipients = [
                ...(this.workspaceSubscriptions.configuration?.email?.additionalRecipients || []),
                this.emailAdditionalRecipient
            ]

            this.store.subscribeTo('email', { additionalRecipients }, 'workspace', null)

            this.$refs.dropdown.closeSubmenus()
            this.emailAdditionalRecipient = ''
        },

        emailRemoveAdditionalRecipient(recipient) {
            let additionalRecipients = this.workspaceSubscriptions.configuration.email.additionalRecipients.filter(r => r != recipient)

            this.store.subscribeTo('email', { additionalRecipients }, 'workspace', null)
        },

        mattermostEnable() {
            this.store.subscribeTo('mattermost', {
                webhookUrl: this.mattermostWebhookUrl,
                channel: this.mattermostChannel,
                username: this.mattermostUsername
            }, 'workspace', false)

            this.mattermostWebhookUrl = this.mattermostChannel = this.mattermostUsername = ''

            this.controls = 'workspace'
        },

        mattermostDisable() {
            this.store.unsubscribeFrom('mattermost', 'workspace')

            this.mattermostWebhookUrl = this.mattermostChannel = this.mattermostUsername = ''

            this.controls = 'workspace'
        },

        muteSubscriptions(interval, target = 'me') {
            let muteUntil = interval === true ? true : zonedToUTC(addMinutes(new Date(), interval))

            this.store.muteSubscription(muteUntil, target)

            this.$refs.dropdown.closeSubmenus()
        },

        unmuteSubscriptions(target = 'me') {
            this.store.unmuteSubscription(target)

            this.$refs.dropdown.closeSubmenus()
        }
    }
}
</script>
