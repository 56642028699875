// FIXME Disable eslint for this in development, remove when ready.
/* eslint-disable no-console */

import { defineStore } from 'pinia'
import dagre from 'dagre'
import api from '@/api'
import { useVueFlow, Position } from '@vue-flow/core'

const {
    findNode,
    setCenter,
    removeNodes
} = useVueFlow({ id: 'vueflow-component' })


export const useContentTracking = defineStore({
    id: 'contentTracking',

    state: () => ({
        isLoading: false,

        inspectedNodeIds: [],
        node_ids: new Set(),
        edge_ids: new Set(),

        hiddenNodes: [],

        root_id: '',

        nodes: [],
        edges: [],
        dark: false,

        branches: {},

        selectedNode: null,

        dagreGraph: new dagre.graphlib.Graph(),
        transform: [ 0, 0, 1 ]
    }),

    actions: {
        async init(nodeId) {
            if (!nodeId) return
            this.root_id = nodeId
            await this.loadApi(this.root_id, 'content')

            setTimeout(() => this.focusNode(this.root_id, 300, 0.3), 300);
        },

        reset() {
            this.nodes = []
            this.edges = []
        },

        // resetTransform() {
        //     setViewport({ x: 0, y: 0, zoom: 0.7 })
        // },

        processGraphData(graph, type) {
            const nodes = []
            const edges = []

            graph.nodes.forEach(node => {
                if (this.node_ids.has(node.key)) {
                    return
                }

                this.node_ids.add(node.key)

                const attrs = node.attributes

                nodes.push({
                    id: node.key,
                    // draggable: false,
                    type: attrs.class.toLowerCase(),
                    data: {...attrs, branch: type, is_origin: this.inspectedNodeIds.includes(node.key)},
                    position: { x: 0, y: 0 },
                    class: 'dark',
                })

                // if (node.key !== this.root_id) {
                //     nodes_counts.push(node.key)
                // }
            })

            graph.edges.forEach(edge => {
                let sourceHandle = 'right-source'
                let targetHandle = "left-target"

                let target = edge.target
                let source = edge.source

                if (type === 'features') {
                    sourceHandle = "top-target"
                    targetHandle = 'bottom-source'

                    if (this.inspectedNodeIds.includes(source)) {
                        target = edge.source
                        source = edge.target
                    }
                }

                if (edge.attributes.label === 'has') {
                    target = edge.source
                    source = edge.target
                }

                edges.push({
                    type: "custom",
                    color: 'black',
                    id: edge.key,
                    target: target,
                    source: source,
                    label: edge.attributes.label,
                    sourceHandle: sourceHandle,
                    targetHandle: targetHandle
                })
            })

            return {nodes, edges}
        },

        // addLoaderNodes(nodes, edges) {
        //     nodes.forEach((node) => {
        //         if (node.type === 'loader' || this.inspectedNodeIds.includes(node.id)) {
        //             return
        //         }
        //
        //         nodes.push({
        //             id: node.id + '-cn',
        //             type: 'loader',
        //             position: {x: 0, y: 0},
        //             class: 'dark',
        //             data: { direction: 'target', load_id: node.id, connections: '' }
        //         })
        //
        //         edges.push({
        //             type: 'output',
        //             color: 'black',
        //             id: node.id + '-ce',
        //             target: node.id + '-cn',
        //             source: node.id,
        //             label: '',
        //             sourceHandle: 'b',
        //             targetHandle: 'a',
        //             animated: true,
        //         })
        //     })
        //
        //     return { nodes, edges }
        // },

        async loadApi(id, type) {
            this.isLoading = true

            await api.route('connections content', true)
                .json({node_id: id, type: type})
                .post()
                .json(data => {
                    this.inspectedNodeIds.push(id)

                    const upstream = this.processGraphData(data.subgraph.upstream, 'upstream')
                    const downstream = this.processGraphData(data.subgraph.downstream, 'downstream')
                    const features = this.processGraphData(data.subgraph.features, 'features')

                    this.branches = {
                        upstream: upstream,
                        downstream: downstream,
                        features: features
                    }

                    this.nodes = [...upstream.nodes, ...downstream.nodes, ...features.nodes, ...this.nodes]
                    this.edges = [...upstream.edges, ...downstream.edges, ...features.edges, ...this.edges]
                    this.isLoading = false

                    // const nodes_counts = []
                    // if (new_nodes.length) {
                    //     api.route('connections counts', true)
                    //         .json({ ids: nodes_counts })
                    //         .post()
                    //         .json(data => {
                    //             this.nodes.forEach((node) => {
                    //                 if (node.type === 'loader') {
                    //                     const n = findNode(node.id)
                    //                     const cid = node.id.slice(0, -3)
                    //
                    //                     if (data.counts[cid] === 0) {
                    //                         this.removeNode(node.id)
                    //                     }
                    //
                    //                     if (data.counts[cid]) {
                    //                         n.data.connections = data.counts[cid]
                    //                     }
                    //                 }
                    //             })
                    //         })
                    // }
                });
        },

        handleBranchLayout(subgraph, direction, align = 'UL') {
            const dagreGraph = new dagre.graphlib.Graph()
            dagreGraph.setDefaultEdgeLabel(() => ({}))

            dagreGraph.setGraph({
                rankdir: direction,
                ranksep: 300,
                ranker: 'tight-tree',
                acyclicer: 'greedy',
                marginx: 100,
                marginy: 100,
                align: align,
            })

            for (const node of subgraph.nodes) {
                const graphNode = findNode(node.id)
                let nodeObj = {}

                if (node.position.x === 0 && node.position.y === 0) {
                    nodeObj = {
                        width: graphNode.dimensions.width + 300,
                        height: graphNode.dimensions.height + 300,
                    }
                } else {
                    nodeObj = {
                        width: graphNode.dimensions.width + 300,
                        height: graphNode.dimensions.height + 400,
                        x: node.position.x,
                        y: node.position.y,
                        // rank: this.rank,
                    }
                }

                dagreGraph.setNode(node.id, nodeObj)
            }

            for (const edge of subgraph.edges) {
                dagreGraph.setEdge(edge.source, edge.target)
            }
            dagre.layout(dagreGraph)

            return subgraph.nodes.map((node) => {
                const nodeWithPosition = dagreGraph.node(node.id)
                let paddingx = 0
                let paddingy = 0

                // TODO handle different directions properly
                if (direction === 'BT') {
                    paddingy = 850
                }

                if (align === 'DR') {
                    paddingx = 3000
                }

                return {
                    ...node,
                    targetPosition: Position.Left,
                    sourcePosition: Position.Bottom,
                    position: {x: nodeWithPosition.x + paddingx, y: nodeWithPosition.y + paddingy},
                }
            })
        },

        handleLayout() {
            // UL, UR, DL, DR
            const upstream = this.handleBranchLayout(this.branches.upstream, 'LR', 'UR')
            const downstream = this.handleBranchLayout(this.branches.downstream, 'LR', 'UL')
            const features = this.handleBranchLayout(this.branches.features, 'BT', 'DL')
            // console.log(upstream, downstream, features)

            this.nodes = [...upstream, ...downstream, ...features]
        },

        paneReady({fitView}) {
            // console.log(fitView)
            // fitView()
        },

        nodeDragStop({event, nodes, node, intersections}) {
            // console.log('Node Drag Stop', {event, nodes, node, intersections})
            console.log(node)
            this.nodes.filter(n => n.id === node.id).forEach(n => {
                n.position = { x: node.position.x, y: node.position.y }
            })
        },

        removeNode(id) {
            removeNodes([id])
            this.nodes = [...this.nodes.filter(n => n.id !== id)]
            this.edges = [...this.edges.filter(n => n.source !== id && n.target !== id)]
        },

        async loadSubgraph(nodeId) {
            await this.loadApi(nodeId, 'content')
            this.removeNode(nodeId + "-cn")
        },

        isLoaded(id) {
            return this.inspectedNodeIds.push(id)
        },

        handleEdgeClick(event) {
            event.preventDefault()
            console.log('Edge click', event)
        },

        handleEdgesChange(changes) {
            // console.log(changes)
            // console.log(changes)
            // const filteredChanges = changes.filter(change => change.type !== 'remove');
            // this.edges.value = [...filteredChanges];
        },

        handleNodeClick(event) {
            this.selectedNode = event.node.id
            // console.log(event.node.id)
            event.connectedEdges.forEach(edge => {
                edge.selected = true
            })
        },

        isRoot(id) {
            return this.root_id === id
        },

        isSelected(id) {
            return this.selectedNode === id
        },

        focusNode(nodeId, duration = 500, zoom = 0.8) {
            const node = findNode(nodeId)
            // console.log(node)

            if (node) {
                const x = node.position.x
                const y = node.position.y

                setCenter(x, y, { duration, zoom })
                this.selectedNode = nodeId
            }
        },

        highlightNode(nodeId) {
            this.selectedNode = nodeId
        },

        cancelHighlightedNodes() {
            this.selectedNode = null
        },

        hideNode(nodeId) {
            const node = findNode(nodeId)

            if (node) {
                node.hidden = true
                this.hiddenNodes.push(nodeId)
                const controlNode = findNode(nodeId + "-cn")
                if (controlNode) {
                    controlNode.hidden = true
                }
            }
        },

        showNode(nodeId) {
            const node = findNode(nodeId)

            if (node) {
                node.hidden = false
                this.hiddenNodes = [...this.hiddenNodes.filter(n => nodeId != n)]
                const controlNode = findNode(nodeId + "-cn")
                if (controlNode) {
                    controlNode.hidden = false
                }
            }
        },

        showAll() {
            this.nodes = this.nodes.map(node => {
                return {...node, hidden: false}
            })
            this.hiddenNodes = []
        },

        hideAll() {
            const hiddenNodes = []
            this.nodes = this.nodes.map(node => {
                if (node.id !== this.root_id && !hiddenNodes.includes(node.id)) {
                    hiddenNodes.push(node.id)
                }
                return {...node, hidden: node.id !== this.root_id}
            })
            this.hiddenNodes = hiddenNodes
        },

        export() {
            // TODO save screenshot
            console.log('TODO export')
        },

        saveData() {
            const data = {
                type: "content",
                data: {
                    nodes: this.nodes,
                    edges: this.edges
                }
            }

            api.route('connections data store', true)
                .json(data)
                .post()
                .json(res => {
                    console.log(res)
                })
        },

        loadData() {
            api.route('connections data get', true)
                .get()
                .json(data => {
                    // console.log(JSON.parse(data[0].data))
                    const graph = JSON.parse(data[0].data)
                    this.nodes = graph.nodes
                    this.edges = graph.edges
                    console.log('Graph loaded')
                });
        }
    }
})

export default useContentTracking
