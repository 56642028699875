<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg">
        <div class="flex mb-4 border-b border-gray-200">
            <a @click.prevent="selectedType = type.id" href="#" class="flex-1 block font-medium px-4 py-4 hover:text-blue-600 group -mb-px" :class="{'border-b border-blue-500 text-blue-600': selectedType == type.id}" :key="type.id" v-for="type in types">
                <div class="font-medium">{{ type.name }}</div>
                <div class="text-xs font-normal group-hover:text-blue-500" :class="selectedType == type.id ? 'text-blue-500' : 'text-gray-800'">{{ type.description }}</div>
            </a>
            <router-link :to="{ name: 'connections', params: { ids: $page.channel.id } }" class="flex-1 flex items-center px-4 py-4 hover:text-blue-600 group">
                <ui-icon name="share-2" class="mr-2"></ui-icon>
                <span class="font-medium">Explore</span>
                <ui-icon name="arrow-right-circle" class="ml-auto"></ui-icon>
            </router-link>
        </div>
        
        <div>
            <connections-section title="Upstream Channels" :connections="channelsUpstream" :connections-store="channelsChannelConnectionsStore" v-if="selectedType == 'upstream'"></connections-section>
            <connections-section title="Downstream Channels" :connections="channelsDownstream" :connections-store="channelsChannelConnectionsStore" v-if="selectedType == 'downstream'"></connections-section>
            <connections-section title="Sources Sharing Properties" :connections="channelsSharingProperties" :connections-store="channelsChannelConnectionsStore" v-if="selectedType == 'sharing-properties'"></connections-section>
            <connections-section title="Connected Features" :connections="channelsConnectedFeatures" :connections-store="channelsChannelConnectionsStore" v-if="selectedType == 'connected-features'"></connections-section>
        </div>    
    </div>
</template>

<script>
import ConnectionsSection from './partials/connections-section'

import useConnectionsStore from '@/stores/channels/channel/connections'

import { mapState, mapStores, mapWritableState } from 'pinia'

export default {
    components: { ConnectionsSection },

    data: () => ({
        types: [
            { id: 'upstream', name: 'Upstream Channels', description: 'Channels this Channel shares content from.' },
            { id: 'downstream', name: 'Downstream Channels', description: 'Channels sharing content from this Channel.' },
            { id: 'sharing-properties', name: 'Sharing Properties', description: 'Channels sharing the same Features.' },
            { id: 'connected-features', name: 'Connected Features', description: 'Features connected to this Channel.' }
        ]
    }),

    computed: {
        ...mapState(useConnectionsStore, [
            'channelsUpstream', 'channelsDownstream', 'channelsSharingProperties', 'channelsConnectedFeatures'
        ]),
        
        ...mapWritableState(useConnectionsStore, [
            'selectedType'
        ]),

        ...mapStores(useConnectionsStore)
    }
}
</script>
