import { defineContentTopChannelsAnalysisStore } from '@/stores/analysis/analyses/content-top-channels'

import number from '@/helpers/number'

import { defineStore } from 'pinia'

export const defineOverviewTopChannelsStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        metric: 'content',

        series: [],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{
                ...series,
                analysisStore: defineContentTopChannelsAnalysisStore({ id: `${settings.id}Analysis` }),
                isLoading: false
            }]

            await this.load()

            this.isInitialized = true
        },

        compareWith(series) {
            this.series.push(series = {
                ...series,
                analysisStore: defineContentTopChannelsAnalysisStore({ id: `${settings.id}Analysis${this.series.length}` }),
                isLoading: false
            })

            this.loadAnalysis(series.analysisStore(), series.filters)
        },

        stopComparing(series) {
            this.series.find(s => s.id == series.id)?.analysisStore().$dispose()
            this.series = this.series.filter(s => s.id != series.id)
        },

        async load() {
            return Promise.all(this.series.map(series => this.loadAnalysis(series.analysisStore(), series.filters)))
        },

        loadAnalysis(store, filters) {
            filters = filters.toPerspective()

            store.series = [{
                datasetType: 'inline-perspective',
                datasetFilters: JSON.stringify(filters),
                values: []
            }]

            if (filters['date']) {
                store.date = filters['date']
            } else {
                store.date = { type: 'past', date: { past: 5, unit: 'years' } }
            }

            store.granularity = 'year'

            return store.loadSeries()
        },

        tableStore(series) {
            let tableColumns, tableRows

            return {
                tableColumns: tableColumns = [
                    { id: 'platform', title: null, sortable: false, additionalStyle: [ 'w-8' ] },
                    { id: 'image', title: null, sortable: false, additionalStyle: [ 'w-8' ] },
                    { id: 'name', title: 'Name', sortable: false },
                    { id: 'content', title: 'Content', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24', this.isMetricHidden('content') ? 'hidden' : '' ] },
                    { id: 'interactions', title: 'Interactions', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24', this.isMetricHidden('interactions') ? 'hidden' : '' ] },
                    { id: 'views', title: 'Views', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24', this.isMetricHidden('views') ? 'hidden' : '' ] },
                    { id: 'followers', title: 'Followers', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24', this.isMetricHidden('followers') ? 'hidden' : '' ] }
                ],

                tableRows: tableRows = series.analysisStore().analysisSeries.slice(0, 6).map(series => ({
                    id: series.name,
                    enabled: series.visible,
                    columns: [
                        {
                            id: 'platform',
                            type: 'icon',
                            click: () => series.visible = ! series.visible,
                            style: ['py-1', 'text-center', 'pl-4'],
                            value: `badges.channel-${series.type}`
                        },
                        {
                            id: 'image',
                            type: 'avatar',
                            click: () => series.visible = ! series.visible,
                            style: ['py-1', 'text-center'],
                            value: { avatar: series.avatar, name: series.title },
                            additionalContentStyle: ['rounded-full']
                        },
                        {
                            id: 'label',
                            type: 'component',
                            component: 'ui-channel-link',
                            props: { avatar: series.avatar, family: 'sources', id: series.id, monitored: series.monitored, title: series.title, type: series.type },
                            click: () => series.visible = ! series.visible,
                            mouseover: () => { series.borderWidth = 2 },
                            mouseout: () => { series.borderWidth = 0 },
                            additionalStyle: ['w-full']
                        },
                        {
                            id: 'content',
                            value: series.content,
                            progress: true,
                            additionalStyle: ['text-right', 'last:pr-4', this.isMetricHidden('content') ? 'hidden' : ''],
                            formatter: (value) => number(value)
                        },
                        {
                            id: 'interactions',
                            value: series.interactions,
                            progress: true,
                            additionalStyle: ['text-right', 'last:pr-4', this.isMetricHidden('interactions') ? 'hidden' : ''],
                            formatter: (value) => number(value)
                        },
                        {
                            id: 'views',
                            value: series.views,
                            progress: true,
                            additionalStyle: ['text-right', 'last:pr-4', this.isMetricHidden('views') ? 'hidden' : ''],
                            formatter: (value) => number(value)
                        },
                        {
                            id: 'followers',
                            value: series.followers,
                            progress: true,
                            additionalStyle: ['text-right', 'last:pr-4', this.isMetricHidden('followers') ? 'hidden' : ''],
                            formatter: (value) => number(value)
                        }
                    ]
                })),

                tableOptions: {
                    headers: tableColumns,
                    limit: 6,
                    rows: tableRows,
                    sorting: false,
                    progressColor: `${series.color}20`
                }
            }
        },

        isLoading(series) {
            return series.analysisStore().isLoading
        },

        setMetric(metric) {
            this.metric = metric
        },

        isMetricHidden(metric) {
            return this.isComparing && this.metric != metric
        }
    }
})

export default defineOverviewTopChannelsStore
