import api from '../api'
import { SearchFilters } from './search-filters'

import filter from 'just-filter'

class ThreeTablesQuery
{
    constructor(endpoint, options = {}) {
        this.endpoint = endpoint

        this.options = {
            types: [],
            filters: {},
            conversation: null,
            parent: null,
            sharedFrom: null,
            replies: null,
            after: null,
            before: null,
            limit: null,
            sorting: null,
            details: null,
            ids: null,
            include: [],
            family: null,
            query: {}
        }

        Object.entries(options).forEach(([ option, value ]) => this[option](value))
    }

    types(types) {
        this.options.types = types
        return this
    }

    filters(filters, value) {
        filters instanceof Object
            ? this.options.filters = filters instanceof SearchFilters ? filters.toPerspective() : filters
            : this.options.filters[filters] = value
        return this
    }

    conversation(conversationId) {
        this.options.conversation = conversationId
        return this
    }

    parent(parentId) {
        this.options.parent = parentId
        return this
    }

    sharedFrom(sharedFrom) {
        this.options.sharedFrom = sharedFrom
        return this
    }

    replies(replies) {
        this.options.replies = replies
        return this
    }

    after(after) {
        this.options.after = after
        return this
    }

    before(before) {
        this.options.before = before
        return this
    }

    limit(limit) {
        this.options.limit = limit
        return this
    }

    sorting(sorting) {
        this.options.sorting = sorting
        return this
    }

    details(details = 'extended') {
        this.options.details = details
        return this
    }

    include(include = []) {
        this.options.include = include
        return this
    }

    ids(ids = []) {
        this.options.ids = ids
        return this
    }

    family(family) {
        this.options.family = family
        return this
    }

    query(query) {
        if (! query) return this.options.query = {}
        this.options.query = { ...this.options.query, ...query }
        return this
    }

    signal(abortController) {
        this.options.abortController = abortController
        return this
    }

    when(condition, trueCallback, falseCallback) {
        trueCallback = trueCallback || (() => {})
        falseCallback = falseCallback || (() => {})

        condition ? trueCallback(this) : falseCallback(this)

        return this
    }

    toRequest() {
        return api.route(this.endpoint, { family: this.options.family, id: this.id })
            .json(filter({
                types: this.options.types,
                filters: JSON.stringify(this.options.filters),
                conversation: this.options.conversation,
                parent: this.options.parent,
                sharedFrom: this.options.sharedFrom,
                replies: this.options.replies,
                after: this.options.after,
                before: this.options.before,
                limit: this.options.limit,
                sorting: this.options.sorting,
                details: this.options.details,
                include: this.options.include.join(','),
                ids: this.options.ids,
                family: this.options.family,
                ...this.options.query
            }, (k, v) => v !== undefined && v !== null))
            .signal(this.options.abortController || new AbortController)
    }

    get(callback) {
        return this.toRequest()
            .post()
            .json(res => {
                if (callback) callback(res.data, res)
                return res.data
            })
    }

    count(callback) {
        return api.route(this.endpoint, { family: this.options.family, id: this.id })
            .json(filter({
                count: true,
                types: this.options.types,
                filters: JSON.stringify(this.options.filters),
                conversation: this.options.conversation,
                parent: this.options.parent,
                sharedFrom: this.options.sharedFrom,
                replies: this.options.replies,
                family: this.options.family,
                ...this.options.query
            }, (k, v) => v !== undefined && v !== null))
            .post()
            .json(res => {
                if (callback) callback(res.data)
                return res.data
            })
    }

    find(id, callback) {
        return api.route(`${this.endpoint} details`, { family: this.options.family, id: id })
            .query(filter({
                details: this.options.details,
                include: this.options.include.join(','),
                family: this.options.family,
                ...this.options.query
            }, (k, v) => v !== undefined && v !== null))
            .signal(this.options.abortController || new AbortController)
            .get()
            .json(res => {
                if (callback) callback(res.data)
                return res.data
            })
    }

    export(format, callback) {
        return api.route(`${this.endpoint} export`, { family: this.options.family, id: this.id })
            .json(filter({
                types: this.options.types,
                filters: JSON.stringify(this.options.filters),
                conversation: this.options.conversation,
                parent: this.options.parent,
                sharedFrom: this.options.sharedFrom,
                replies: this.options.replies,
                after: this.options.after,
                before: this.options.before,
                limit: this.options.limit,
                sorting: this.options.sorting,
                details: this.options.details,
                include: this.options.include.join(','),
                ids: this.options.ids,
                family: this.options.family,
                format,
                ...this.options.query
            }, (k, v) => v !== undefined && v !== null))
            .post()
            .blob(res => callback(res))
    }
}

export const channels = options => new ThreeTablesQuery('monitor channels', options)
export const content = options => new ThreeTablesQuery('monitor content', options)
export const features = options => new ThreeTablesQuery('monitor features', options)
