import defineCards from '@/stores/reusable/cards'
import useMyMonitoredChannelsStore from '@/stores/me/monitored-channels'

import { sortAlphabetically, sortByDate } from '@/helpers/sorting'

export const useIndexCardsMonitoredStore = defineCards({
    id: 'channelsIndexCardsMonitored',

    source: useMyMonitoredChannelsStore,
    layout: 'table',

    paginated: true,
    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => sortByDate(items, 'lastUsedAt').reverse()
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => sortByDate(items, 'updatedAt').reverse()
        }
    ]
})

export default useIndexCardsMonitoredStore
