<template>
    <div class="bg-white w-full overflow-hidden shadow rounded py-2 mb-4">
        <a href="#" class="flex items-center font-medium px-6 py-2" @click.prevent="toggleVisibility">
            {{ nodeList.length }} nodes
            <ui-icon :name="shown ? 'chevron-up' : 'chevron-down'" class="ml-auto" v-if="nodeList.length"></ui-icon>
        </a>

        <div class="border-t border-gray-200 mt-2 pt-2" v-if="shown">
            <div class="relative px-2">
                <div class="absolute left-2 top-0 h-full w-10 flex items-center justify-center z-30">
                    <ui-icon name="search"></ui-icon>
                </div>
                <ui-input type="search" class="pl-8" placeholder="Search..." v-model="searchQuery"></ui-input>
            </div>

            <div class="p-2 overflow-y-auto max-h-80" v-if="nodeList.length">
                <div :key="node.id" v-for="node in nodeList" class="flex items-center justify-between rounded transition hover:bg-blue-400 hover:text-white group" @mouseenter="store.highlightNode(node.id)" @mouseleave="store.cancelHighlightedNodes()">
                    <a href="#" class="py-1 px-2 truncate" @click.prevent="store.focusNode(node.id)">
                        <ui-icon :name="node.icon" class="shrink-0 mr-1"></ui-icon>
                        <span>{{ node.name }}</span>
                    </a>
                    <a href="#" class="py-1 hidden group-hover:block" @click.prevent="store.hideNode(node.id)" title="Hide node" v-if="!store.hiddenNodes.includes(node.id) && store.inspectedNodeIds[0] != node.id">
                        <ui-icon name="eye-off" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
                    </a>
                    <a href="#" class="py-1" @click.prevent="store.showNode(node.id)" title="Show node" v-if="store.hiddenNodes.includes(node.id)">
                        <ui-icon name="eye-off" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
                    </a>
                </div>
            </div>

            <div class="border-t border-gray-200 pt-2">
                <a @click.prevent="store.showAll" href="#" class="flex items-center font-medium px-6 py-1" :class="store.isLoading ? 'text-gray-400' : 'hover:text-blue-600'">
                    Show Hidden Nodes
                    <ui-icon name="eye" class="ml-auto"></ui-icon>
                </a>
                <a @click.prevent="store.hideAll" href="#" class="flex items-center font-medium px-6 py-1" :class="store.isLoading ? 'text-gray-400' : 'hover:text-blue-600'">
                    Hide All Nodes
                    <ui-icon name="eye-off" class="ml-auto"></ui-icon>
                </a>

                <a @click.prevent="store.export" href="#" class="flex items-center font-medium px-6 py-1" :class="store.isLoading ? 'text-gray-400' : 'hover:text-blue-600'" v-if="store.export">
                    Export
                    <ui-icon name="download" class="ml-auto"></ui-icon>
                </a>
            </div>
        </div>
    </div>
</template>


<script>

import textSearch from '@/helpers/text-search'

export default {
    components: {},

    props: {
        nodes: { type: Object, required: true },
        store: { type: Object, required: true }
    },

    data: () => ({
        shown: true,
        searchQuery: ""
    }),

    computed: {
        nodeList() {
            return this.searchQuery ? textSearch(this.searchQuery, this.nodes, n => n.name) : this.nodes
        }
    },

    methods: {
        toggleVisibility() {
            this.shown = !this.shown
        },
    }
}
</script>
