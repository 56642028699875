import { defineContentTopPlatformsAnalysisStore } from '@/stores/analysis/analyses/content-top-platforms'

import useRouter from '@/router'
import number from '@/helpers/number'
import { nodeTypeIds } from '@/helpers/types'

import { defineStore } from 'pinia'

export const defineOverviewPlatformsShareStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        type: 'content',

        series: [],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{
                ...series,
                analysisStore: defineContentTopPlatformsAnalysisStore({ id: `${settings.id}Analysis` }),
            }]

            await this.load()

            this.isInitialized = true
        },

        compareWith(series) {
            this.series.push(series = {
                ...series,
                analysisStore: defineContentTopPlatformsAnalysisStore({ id: `${settings.id}Analysis${this.series.length}` })
            })

            this.loadAnalysis(series.analysisStore(), series.filters)
        },

        stopComparing(series) {
            this.series.find(s => s.id == series.id)?.analysisStore().$dispose()
            this.series = this.series.filter(s => s.id != series.id)
        },

        async load() {
            return Promise.all(this.series.map(series => this.loadAnalysis(series.analysisStore(), series.filters)))
        },

        toggleType() {
            this.type = this.type == 'content' ? 'interactions' : 'content'
        },

        loadAnalysis(store, filters) {
            filters = filters.toPerspective()

            store.series = [{
                datasetType: 'inline-perspective',
                datasetFilters: JSON.stringify(filters),
                meta: { metric: 'interactions' },
                values: []
            }]

            if (filters['date']) {
                store.date = filters['date']
            } else {
                store.date = { type: 'past', date: { past: 5, unit: 'years' } }
            }

            return store.loadSeries()
        },

        analysisOptions(series) {
            return {
                boost: { useGPUTranslations: true },
                chart: {
                    type: 'bar',
                    spacing: [0, 0, 0, 0]
                },
                credits: { enabled: false },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        grouping: false,
                        groupPadding: 0
                    }
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: { text: '' },
                    labels: {
                        align: 'center',
                        style: { color: '#8599a3' }
                    }
                },
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    hideDelay: 100,
                    padding: 8,
                    pointFormatter: function () {
                        return `<strong>${number(this.y)}</strong>`
                    },
                    shadow: false,
                    shared: true,
                    style: { color: '#fff', textAlign: 'center' },
                    xDateFormat: '%Y-%m-%d'
                },
                series: this.resolveAnalysisSeries(series),
                title: {
                    text: ''
                }
            }
        },

        resolveAnalysisSeries(series) {
            let platforms = this.series.reduce((platforms, series) => {
                series.analysisStore().analysisSeries.forEach(analysisSeries => {
                    let platform = platforms.find(p => p.id == analysisSeries.platform)

                    if (! platform) platforms.push(platform = { id: analysisSeries.platform, name: analysisSeries.label, values: {} })

                    platform.values[series.id] = analysisSeries[this.type]
                })

                return platforms
            }, [])

            platforms = platforms.sort((a, b) => b.values[this.series[0].id] - a.values[this.series[0].id])

            return [
                ...platforms.slice(0, Math.min(platforms.length, 5)).map((platform, index) => ({
                    name: 'Platform',
                    color: series.color,
                    data: [{ name: platform.name, y: platform.values[series.id], x: index }],
                    events: { click: () => window.open(this.resolveSeriesUrl(series, [ platform.id ]), '_blank') }
                })),
                {
                    name: 'Platform',
                    color: '#d1d5db',
                    data: [{ name: 'Other', y: platforms.slice(6).reduce((total, platform) => total + platform.values[series.id], 0), x: Math.min(platforms.length, 5) }],
                    events: { click: () => window.open(this.resolveSeriesOtherUrl(series, platforms), '_blank') }
                }
            ]
        },

        resolveSeriesUrl(series, platforms) {
            return useRouter().resolve({
                name: 'content.perspectives.perspective',
                query: series.filters.clone()
                    .set('type', nodeTypeIds(t => t.family == 'content' && ! t.isReply && platforms.includes(t.platform)))
                    .toQuery(),
                params: { perspectiveId: 'new' }
            }).href
        },

        resolveSeriesOtherUrl(series, platforms) {
            let allPlatforms = [
                'facebook', 'instagram', 'reddit', 'telegram', 'tik-tok', 'vkontakte', 'web-feeds', 'web-sites', 'x', 'youtube',
                '2ch', '4chan'
            ]
            let shownPlatforms = platforms.slice(0, Math.min(platforms.length, 5)).map(p => p.id)
            let otherPlatforms = allPlatforms.filter(p => ! shownPlatforms.includes(p))

            return this.resolveSeriesUrl(series, otherPlatforms)
        }
    }
})

export default defineOverviewPlatformsShareStore
