import Main from './Main.vue'
import AuthLogin from './components/auth/login'
import AuthActivate from './components/auth/activate'
import UiScreen from './components/ui/screen'
import AuthPasswordRecoveryRequest from './components/auth/password-recovery-request'
import AuthPasswordRecoveryReset from './components/auth/password-recovery-reset'
import App from './App.vue'
import AnalysisScreen from './components/analysis/screen'
import AnalysisIndex from '@/components/analysis/index'
import AnalysisMyAnalyses from '@/components/analysis/my-analyses'
import AnalysisMyAnalysesNew from '@/components/analysis/my-analyses-new'
import AnalysisDetails from '@/components/analysis/analysis'
import ChannelsScreen from './components/channels/screen'
import ChannelsIndex from './components/channels/index'
import ChannelsDetails from './components/channels/channel'
import ConnectionsScreen from './components/connections/connections'
// import ContentTracking from './components/connections/content'
import ContentScreen from './components/content/screen'
import ContentIndex from './components/content/index'
import ContentPerspective from './components/content/perspective'
import ContentDetails from './components/content/details'
import DashboardsScreen from './components/dashboards/screen'
import DashboardsIndex from '@/components/dashboards/index'
import DashboardsBoard from '@/components/dashboards/board'
import FeaturesScreen from './components/features/screen'
import FeaturesDetails from './components/features/feature'
import SettingsScreen from './components/settings/screen'
import HelpScreen from './components/support/help/screen'
import HelpPage from './components/support/help/page'
import ReleaseNotesScreen from './components/support/release-notes/screen'
import ReleaseNotesPage from './components/support/release-notes/page'

const pages = import.meta.glob('@/pages/*.js', { eager: true, import: 'default' })

export default [
    // Root redirect to default section (content)
    { path: '', redirect: { name: 'content' } },

    // Public routes
    {
        component: Main,
        meta: { guestOnly: true },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: { page: pages['/src/pages/auth-login.js'] },
                component: AuthLogin
            },
            {
                path: '/login/activate/:token',
                name: 'login.activate',
                meta: { page: pages['/src/pages/auth-activate.js'] },
                component: AuthActivate
            },
            {
                path: '/login/social',
                name: 'login.social',
                meta: { page: pages['/src/pages/auth-social.js'] },
                component: UiScreen
            },
            {
                path: '/password-recovery/request',
                name: 'password-recovery.request',
                meta: { page: pages['/src/pages/auth-password-recovery-request.js'] },
                component: AuthPasswordRecoveryRequest
            },
            {
                path: '/password-recovery/reset/:token',
                name: 'password-recovery.reset',
                meta: { page: pages['/src/pages/auth-password-recovery-reset.js'] },
                component: AuthPasswordRecoveryReset
            }
        ]
    },

    // Application routes (requires authentication)
    {
        component: App,
        meta: { authenticatedOnly: true },
        children: [
            {
                path: '/analysis',
                component: AnalysisScreen,
                children: [
                    {
                        path: '',
                        name: 'analysis',
                        component: AnalysisIndex,
                        meta: { page: pages['/src/pages/analysis-index.js'] }
                    },
                    {
                        path: 'analyses',
                        name: 'analysis.my-analyses',
                        component: AnalysisMyAnalyses,
                        meta: { page: pages['/src/pages/analysis-my-analyses.js'] }
                    },
                    {
                        path: 'analyses/new',
                        name: 'analysis.my-analyses.new',
                        component: AnalysisMyAnalysesNew,
                        meta: { page: pages['/src/pages/analysis-my-analyses-new.js'] }
                    },
                    {
                        path: ':type/:id',
                        name: 'analysis.analysis.details',
                        component: AnalysisDetails,
                        meta: { page: pages['/src/pages/analysis-my-analyses-analysis.js'] }
                    }
                ]
            },
            {
                path: '/channels',
                component: ChannelsScreen,
                children: [
                    {
                        path: '',
                        name: 'channels',
                        component: ChannelsIndex,
                        meta: { page: pages['/src/pages/channels-index.js'] }
                    },
                    {
                        path: ':id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
                        name: 'channels.channel.information',
                        component: ChannelsDetails,
                        meta: { page: pages['/src/pages/channels-channel.js'] }
                    }
                ]
            },
            {
                path: '/connections',
                children: [
                    {
                        path: ':ids?',
                        name: 'connections',
                        component: ConnectionsScreen,
                        meta: { page: pages['/src/pages/connections.js'] },
                    },
                    // {
                    //     path: 'content/:id?',
                    //     name: 'connections.content',
                    //     component: ContentTracking,
                    //     meta: { page: pages['/src/pages/content-tracking.js'] }
                    // }
                ]
            },
            {
                path: '/content',
                component: ContentScreen,
                children: [
                    {
                        path: '',
                        name: 'content',
                        component: ContentIndex,
                        meta: { page: pages['/src/pages/content-index.js'] }
                    },
                    {
                        path: 'perspectives/:perspectiveId',
                        name: 'content.perspectives.perspective',
                        component: ContentPerspective,
                        meta: { page: pages['/src/pages/content-perspectives-perspective.js'] }
                    },
                    {
                        path: ':id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
                        name: 'content.details',
                        component: ContentDetails,
                        meta: { page: pages['/src/pages/content-details.js'] }
                    }
                ]
            },

            {
                path: '/dashboards',
                component: DashboardsScreen,
                children: [
                    {
                        path: '',
                        name: 'dashboards',
                        component: DashboardsIndex,
                        meta: { page: pages['/src/pages/dashboards-index.js'] }
                    },
                    {
                        path: ':id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
                        name: 'dashboard.boards.board',
                        component: DashboardsBoard,
                        meta: { page: pages['/src/pages/dashboards-board.js'] }
                    }
                ]
            },

            {
                path: '/features',
                component: FeaturesScreen,
                children: [
                    {
                        path: ':id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
                        name: 'features.details',
                        component: FeaturesDetails,
                        meta: { page: pages['/src/pages/features-feature.js'] }
                    }
                ]
            },

            {
                path: '/settings',
                component: UiScreen,
                children: [
                    {
                        path: '',
                        name: 'settings',
                        component: SettingsScreen,
                        meta: { page: pages['/src/pages/settings.js'] },
                    },
                    {
                        path: 'account/login/social',
                        name: 'settings.account.login.social',
                        component: UiScreen,
                        meta: { page: pages['/src/pages/settings-account-login-social.js'] }
                    }
                ]
            },

            {
                path: '/help',
                component: HelpScreen,
                children: [
                    {
                        path: ':page/:section?',
                        name: 'help.page',
                        component: HelpPage,
                        meta: { page: pages['/src/pages/support-help-page.js'] }
                    },
                ]
            },

            {
                path: '/release-notes',
                component: ReleaseNotesScreen,
                children: [
                    {
                        path: ':page/:section?',
                        name: 'release-notes.page',
                        component: ReleaseNotesPage,
                        meta: { page: pages['/src/pages/support-release-notes.js'] }
                    }
                ]
            }
        ]
    },

    // Catch-all route for "not found" pages
    { path: '/:pathMatch(.*)*', redirect: { name: 'content' } }
]
