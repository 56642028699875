import useConnectionsModalsNodeDetailsStore from '@/stores/connections/modals/node-details'
import useSupportDebugStore from '@/stores/support/debug'

import api from '@/api'
import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { defineStore } from 'pinia'
import forceAtlas2 from 'graphology-layout-forceatlas2'
import noverlap from 'graphology-layout-noverlap'
import gexf from 'graphology-gexf/browser'
import Graphology from 'graphology'
import Sigma from 'sigma'

import CircleNodeProgram from 'sigma/rendering/webgl/programs/node.fast'
import ImageNodeProgram from 'sigma/rendering/webgl/programs/node.image'
import FastEdgeProgram from 'sigma/rendering/webgl/programs/edge.fast'
// import ArrowEdgeProgram from './edge.arrow'
import { markRaw } from 'vue'

import { node_styles } from '@/components/connections/node_styles.js'


const defineConnectionsGraph = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            graph: null,
            renderer: null,

            isLoading: false,
            dataset: null,

            inspectedNodeIds: [],
            nodes: [],
            hiddenNodes: [],

            type: 'actor-feature',

            shownChannels: [],
            shownFeatures: [],

            channelsInGraph: [],
            featuresInGraph: [],

            hoveredNode: null,
            hoveredNeighbors: null,
            highlightedNode: null,

            nodeStyles: node_styles,

            stats: {}
        }),

        actions: {
            initialize(type = 'actor-feature') {
                this.graph = markRaw(new Graphology({ multi: true }))
                this.hiddenNodes = []

                if (type) {
                    this.type = type
                }

                let container = document.getElementById('connections-graph-container')

                this.renderer =  markRaw(new Sigma(this.graph, container, {
                    renderEdgeLabels: true,
                    enableEdgeEvents: true,
                    nodeProgramClasses: { circle: CircleNodeProgram, image: ImageNodeProgram() },
                    edgeProgramClasses: {
                        // arrow: ArrowEdgeProgram,
                        line: FastEdgeProgram
                    },
                    labelFont: 'Roboto, sans-serif',
                    labelSize: 11,
                    labelWeight: '500',
                    labelColor: { color: '#677f8c' },
                    edgeLabelFont: 'Roboto, sans-serif',
                    edgeLabelSize: 10,
                    edgeLabelWeight: '400',
                    edgeLabelColor: { color: '#677f8c' },
                    zIndex: true,
                    allowInvalidContainer: true,
                }))

                this.renderer.on('clickNode', event => this.clickNode(event))
                this.renderer.on('enterNode', event => this.setHoveredNode(event.node))
                this.renderer.on('leaveNode', () => this.setHoveredNode())

                this.renderer.setSetting('nodeReducer', (node, data) => this.nodeReduces(node, data))
                this.renderer.setSetting('edgeReducer', (edge, data) => this.edgeReducer(edge, data))
            },

            computeStats() {
                const stats = {
                    classes: {},
                    subclasses: {},
                    object_types: {},
                    edges: {}
                }

                this.graph.forEachNode((n, a) => {
                    if (stats['classes'][a.class]) {
                        stats['classes'][a.class]++
                    } else {
                        stats['classes'][a.class] = 1
                    }

                    stats['classes'] = Object.entries(stats['classes'])
                        .sort(([,a],[,b]) => b-a)
                        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

                    if (stats['object_types'][a.object_type]) {
                        stats['object_types'][a.object_type]++
                    } else {
                        stats['object_types'][a.object_type] = 1
                    }

                    stats['object_types'] = Object.entries(stats['object_types'])
                        .sort(([,a],[,b]) => b-a)
                        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                })

                this.graph.forEachEdge((n, a) => {
                    if (stats['edges'][a.label]) {
                        stats['edges'][a.label]++
                    } else {
                        stats['edges'][a.label] = 1
                    }
                })

                stats['edges'] = Object.entries(stats['edges'])
                    .sort(([,a],[,b]) => b-a)
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

                this.stats = stats
            },

            reset() {
                if (this.renderer) {
                    this.renderer.removeAllListeners()
                    this.renderer.clear()
                }
                if (this.graph) this.graph.clear()
                this.$reset()
            },

            inspectNode(nodeIds) {
                this.setHoveredNode()
                this.isLoading = true

                api.route('connections index', true)
                    .json({ node_ids: nodeIds, type: this.type })
                    .post()
                    .json(res => {
                        const dataset = res.subgraph

                        this.inspectedNodeIds = [ ...new Set([ ...this.inspectedNodeIds, ...nodeIds ]) ]

                        dataset.nodes.forEach(n => {
                            this.processNode(n)
                            !this.graph.hasNode(n.key) && this.graph.mergeNode(n.key, n.attributes)
                        })

                        dataset.edges.forEach(e => {
                            this.processEdge(e)
                            !this.graph.hasEdge(e.key) && this.graph.mergeEdgeWithKey(e.key, e.source, e.target, e.attributes)
                        })

                        this.trimGraph(this.graph)
                        this.scaleGraphNodes(this.graph)
                        this.layoutGraph(this.graph)

                        let newNodes = []
                        dataset.nodes.forEach(n => {
                            if (n) {
                                const found = this.nodes.find(m => m.key == n.key)
                                if (!found) {
                                    newNodes.push(n)
                                }
                            }
                        })

                        this.nodes = [ ...this.nodes, ...newNodes ]
                        this.shownChannels = [ ...new Set(this.nodes.filter(n => n.attributes.class === 'Channel').map(n => n.attributes.object_type)) ]
                        this.shownFeatures = [ ...new Set(this.nodes.filter(n => n.attributes.class === 'Feature').map(n => n.attributes.object_type)) ]

                        this.channelsInGraph = [ ...this.shownChannels ]
                        this.featuresInGraph = [ ...this.shownFeatures ]

                        // Enable mouse captor after loading - prevent mouse events on empty graph
                        this.renderer.getMouseCaptor().enabled = true

                        window.history.pushState({}, "", "/connections/" + this.inspectedNodeIds.join(',') + "?type=" + this.type)
                        this.computeStats()
                        this.isLoading = false
                    })
            },

            layoutGraph(graph) {
                this.randomPositionsSeed(graph)

                const layout_settings = {
                    barnesHutOptimize: true,
                    barnesHutTheta: 0.5,
                    strongGravityMode: false,

                    gravity: 1,
                    scalingRatio: 5,
                    linLogMode: false,

                    adjustSizes: false,
                    weighted: false,
                    outboundAttractionDistribution: false,

                    // slowDown: 1,
                    // edgeWeightInfluence: 1,
                }

                forceAtlas2.assign(graph, { iterations: 40, settings: layout_settings })

                noverlap.assign(graph, {
                    maxIterations: 10,
                    settings: { ratio: 1, margin: 100, gridSize: 100 },
                    inputReducer: (key, attr) => ({ x: attr.x, y: attr.y, size: attr.size }),
                    outputReducer: (key, pos) => {
                        graph.updateNodeAttributes(key, attr => ({ ...attr, x: pos.x, y: pos.y }))
                        return { x: pos.x, y: pos.y }
                    }
                });
            },

            randomPositionsSeed(graph) {
                const minValue = -10000
                const maxValue = 10000

                const uuidToNum = (str) => {
                    const hexString = str.replace(/-/g, '')
                    const hexValue = BigInt(`0x${hexString}`)
                    const maxHexValue = BigInt('0xffffffffffffffffffffffffffffffff');
                    const scaledValue = (hexValue * BigInt(maxValue - minValue + 1)) / maxHexValue
                    return Number(scaledValue) + minValue
                }

                graph.forEachNode((n, a) => {
                    a.x = uuidToNum(n)
                    a.y = uuidToNum(n.split('').reverse().join(''))
                })
            },

            nodeReduces(node, data) {
                if (
                    this.hoveredNeighbors &&
                    !this.hoveredNeighbors.has(node) &&
                    this.hoveredNode !== node
                    // && !this.inspectedNodeIds.includes(data.node_id)
                ) {
                    return { ...data, type: 'circle', label: '', color: '#e0e5e8' }
                }

                return data
            },

            edgeReducer(edge, data) {
                if (this.hoveredNode) {
                    let ext = this.graph.extremities(edge)
                    let isHovered = ext.includes(this.hoveredNode) &&
                        (this.hoveredNeighbors.has(ext[0]) || this.hoveredNeighbors.has(ext[1]))

                    return {
                        ...data,
                        color: isHovered ? '#2c404c' : '#e0e5e8',
                        zIndex: isHovered ? 999 : 0,
                        label: isHovered ? data.label : ''
                    }
                }

                return data
            },

            setType(type) {
                // disable mouse events on empty graph - prevents
                this.renderer.getMouseCaptor().enabled = false

                this.renderer.clear()
                this.graph.clear()
                this.graph.clearEdges()

                this.type = type
                this.stats = {}

                this.nodes = []
                this.hiddenNodes = []

                // hack fix for endless loading when getting large subgraphs for full - limiting IDs
                if (type === 'all') {
                    this.inspectedNodeIds = [this.inspectedNodeIds[0]]
                } else if (this.inspectedNodeIds.length > 3) {
                    this.inspectedNodeIds = this.inspectedNodeIds.slice(0, 3);
                }

                this.inspectNode(this.inspectedNodeIds)
                this.setHoveredNode()
            },

            setShownChannels(shownChannels) {
                this.shownChannels = shownChannels
                let hidden = [ ...this.hiddenNodes ]

                this.graph.forEachNode((n, a) => {
                    if (this.inspectedNodeIds.includes(a.node_id)) return

                    if (a.class === 'Channel') {
                        if (this.shownChannels.includes(a.object_type)) {
                            a.hidden = false
                            hidden = [ ...hidden.filter(n => a.node_id != n) ]
                        } else {
                            a.hidden = true
                            hidden.push(a.node_id)
                        }
                    }
                })

                this.hiddenNodes = [ ...hidden ]
                this.renderer.refresh()
            },

            setShownFeatures(shownFeatures) {
                this.shownFeatures = shownFeatures
                let hidden = [ ...this.hiddenNodes ]

                this.graph.forEachNode((n, a) => {
                    if (this.inspectedNodeIds.includes(a.node_id)) return

                    if (a.class === 'Feature') {
                        if (this.shownFeatures.includes(a.object_type) || this.shownFeatures.includes(a.object_type)) {
                            a.hidden = false
                            hidden = [ ...hidden.filter(n => a.node_id != n) ]
                        } else {
                            a.hidden = true
                            hidden.push(a.node_id)
                            //this.hideIsolatedNeighbors(a.node_id)
                        }
                    }
                })

                this.hiddenNodes = [ ...hidden ]
                this.renderer.refresh()
            },

            focusNode(key) {
                this.renderer.getCamera().animate(
                    { ...this.renderer.getNodeDisplayData(key), ratio: 0.2 },
                    { duration: 500 }
                )
            },

            zoomIn() {
                this.renderer.getCamera().animatedZoom()
            },

            zoomOut() {
                this.renderer.getCamera().animatedUnzoom()
            },

            export() {
                if (this.isLoading) return

                let gexfExport = gexf.write(this.graph)

                let anchor = document.createElement('a')
                anchor.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(gexfExport))
                anchor.setAttribute('download', 'export.gexf')
                anchor.style.display = 'none'

                document.body.appendChild(anchor)
                anchor.click()
                document.body.removeChild(anchor)
            },

            processNode(node) {
                // dont override image attribute on contents
                delete node.attributes.image

                const style = this.nodeStyles[node.attributes.object_type] || this.nodeStyles['default']

                node.attributes = {
                    ...node.attributes,
                    type: 'image',
                    hidden: false,
                    ...style
                }
            },

            processEdge(edge) {
                edge.attributes.type = 'arrow'
                edge.attributes.size = scaleLinear().domain([ 1, 300 ]).range([ 1, 5 ]).clamp(true)(edge.attributes.weight)
            },

            trimGraph(graph) {
                graph.forEachNode((n, a) => {
                    if (this.inspectedNodeIds.includes(a.node_id)) return

                    // if (a.subclass === 'Fingerprint' && graph.neighbors(n).length < 2) {
                    //     return graph.dropNode(n)
                    // }

                    if (! graph.neighbors(n).length) {
                        return graph.dropNode(n)
                    }
                })
            },

            scaleGraphNodes(graph) {
                let scale = scaleLinear()
                    .domain(extent(graph.mapNodes(n => graph.degree(n))))
                    .range([
                        scaleLinear().domain([ 100, 10000 ]).range([ 8, 2 ]).clamp(true)(graph.order),
                        scaleLinear().domain([ 100, 10000 ]).range([ 25, 6 ]).clamp(true)(graph.order)
                    ])

                graph.forEachNode((n, a) => {
                    if (this.inspectedNodeIds.includes(a.node_id)) {
                        graph.setNodeAttribute(n, 'size', 20)
                        return
                    }

                    if (a.class === 'Content') {
                        graph.setNodeAttribute(n, 'size',
                            scaleLinear().domain([ 100, 10000 ]).range([ 4, 1 ]).clamp(true)(graph.order))
                    } else {
                        graph.setNodeAttribute(n, 'size', scale(graph.degree(n)))
                    }
                })
            },

            debugNode(node) {
                useSupportDebugStore().show({
                    ...this.graph.getNodeAttributes(node),
                    neighbors: this.graph.mapNeighbors(node, (n, a) => a),
                    edges: this.graph.mapEdges(node, (e, a) => a)
                })
            },

            setHoveredNode(node) {
                if (node) {
                    this.hoveredNode = node

                    let nodesQueue = this.graph.neighbors(node)
                    let nodesTraversed = new Set()
                    let neighbors = []

                    while (nodesQueue.length > 0) {
                        let n = nodesQueue.pop()
                        nodesTraversed.add(n)
                        neighbors.push(n)

                        let a = this.graph.getNodeAttributes(n)
                        let deg = this.graph.degree(n)

                        if (a.class === 'Channel' || deg >= 10) continue;

                        this.graph.neighbors(n).forEach(x => {
                            if (! nodesTraversed.has(x)) nodesQueue.push(x)
                        })
                    }

                    this.hoveredNeighbors = new Set(neighbors)
                } else {
                    this.hoveredNode = null
                    this.hoveredNeighbors = null
                }

                this.renderer.scheduleRefresh()
            },

            hideIsolatedNeighbors(nodeId) {
                this.graph.neighbors(nodeId).forEach((id ) => {
                    if (this.graph.neighbors(id).length < 2) {
                        const node = this.graph.getNodeAttributes(id)
                        node.hidden = true
                        this.hiddenNodes.push(id)
                    }
                })
            },

            showNode(nodeId) {
                if (this.graph.hasNode(nodeId)) {
                    let node = this.graph.getNodeAttributes(nodeId)
                    node.hidden = false
                    this.hiddenNodes = [ ...this.hiddenNodes.filter(n => nodeId != n) ]
                    this.setHoveredNode()
                }
            },

            hideNode(nodeId) {
                if (this.inspectedNodeIds[0] == nodeId) return

                if (this.graph.hasNode(nodeId)) {
                    const node = this.graph.getNodeAttributes(nodeId)
                    node.hidden = true
                    this.hiddenNodes.push(nodeId)

                    // Hide also isolated neighbors - maybe this is not necessary
                    this.hideIsolatedNeighbors(nodeId)
                    this.setHoveredNode()
                }
            },

            showAll() {
                this.graph.forEachNode((id, attributes) => {
                    attributes.hidden = false
                })
                this.hiddenNodes = []
                this.renderer.refresh()
            },

            hideAll() {
                let hiddenNodes = []
                this.graph.forEachNode((id, attributes) => {
                    if (this.inspectedNodeIds[0] != id) {
                        attributes.hidden = true
                        hiddenNodes.push(id)
                    }
                })
                this.hiddenNodes = [ ...hiddenNodes ]
                this.renderer.refresh()
            },

            highlightNode(nodeId) {
                this.highlightedNode = nodeId

                setTimeout(() => {
                    if (this.highlightedNode == nodeId) {
                        const node = this.graph.getNodeAttributes(nodeId)
                        node.highlighted = true
                        this.renderer.refresh()
                    }
                }, 200)
            },

            cancelHighlightedNodes() {
                if (this.highlightedNode) {
                    this.graph.forEachNode((id, attributes) => {
                        attributes.highlighted = false
                    })

                    this.renderer.refresh()
                    this.highlightedNode = null
                }
            },

            clickNode(event) {
                if (event.event.original.altKey) {
                    this.debugNode(event.node)
                } else {
                    let attributes = this.graph.getNodeAttributes(event.node)
                    useConnectionsModalsNodeDetailsStore().open(attributes.class, attributes.node_id, this)
                }
            }
        }
    })
}

export default defineConnectionsGraph
