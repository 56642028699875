<template>
    <ui-async :guards="! analysisStore.isLoading" class="py-4">
        <div class="flex flex-wrap justify-center gap-6">
            <div class="relative w-56 h-12" :key="series.platform" v-for="series in analysisStore.analysisSeries">
                <ui-analysis :store="chartConfiguration(series)" class="h-full w-full pl-10"></ui-analysis>

                <div class="flex items-center space-x-2 absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#ffffff]">
                    <ui-icon :name="`badges.channel-${series.badge}`" class="text-4xl"></ui-icon>
                    <div>
                        <div class="flex items-center space-x-1 text-2xl font-semibold leading-tight">
                            <ui-icon name="interactions-solid" class="text-gray-800 text-lg"></ui-icon>
                            {{$number(series.interactions)}}
                        </div>
                        <div class="flex items-center space-x-1 text-xs leading-tight text-gray-700 font-medium">
                            <ui-icon name="hash"></ui-icon>
                            {{$number(series.content)}}
                        </div>
                        <div class="text-xs text-gray-700 font-medium mt-0.5">
                            {{series.label}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ui-async>
</template>

<script>
export default {
    computed: {
        analysisStore() {
            return this.store.statistics.analyses.contentPerspectiveTopPlatformsAnalysis.store()
        }
    },

    methods: {
        chartConfiguration(series) {
            return {
                hasValues: true,
                analysisOptions: {
                    boost: { useGPUTranslations: true },
                    chart: {
                        type: 'areaspline',
                        spacing: [0, 0, 0, 0]
                    },
                    credits: { enabled: false },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: true,
                            connectNulls: false,
                            dataLabels: { enabled: false },
                            marker: { enabled: false },
                            enableMouseTracking: false,
                            turboThreshold: 2500,
                            lineWidth: 1
                        },
                        areaspline: {
                            fillOpacity: 0.1
                        }
                    },
                    series: [{
                        name: series.label,
                        color: series.color,
                        data: series.values,
                        visible: series.visible,
                        yAxis: 'interactions'
                    }],
                    title: {
                        text: ''
                    },
                    xAxis: {
                        type: 'datetime',
                        visible: false
                    },
                    yAxis: [{
                        id: 'interactions',
                        visible: false
                    }]
                }
            }
        }
    }
}
</script>
