import useMyNotificationsStore from '@/stores/me/notifications'
import useChannelsMyChannelsMonitoringRequestsStore from '@/stores/me/monitoring-requests'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

export const useChannelsModalsLibraryAddChannelStore = defineForm({
    id: 'channelsModalsLibraryAddChannel',

    state: () => ({
        types: [
            {
                name: 'Facebook Page',
                id: 'facebook',
                icon: 'badges.channel-facebook-page',
                placeholder: 'https://facebook.com/projektn.sk, @projektn.sk'
            },
            {
                name: 'Facebook Group',
                id: 'facebook-group',
                icon: 'badges.channel-facebook-group',
                placeholder: 'https://facebook.com/groups/vyvojari, @vyvojari'
            },
            {
                name: 'Instagram Profile',
                id: 'instagram-profile',
                icon: 'badges.channel-instagram-profile',
                placeholder: 'https://www.instagram.com/delniq, @delniq'
            },
            {
                name: 'Reddit User or Subreddit',
                id: 'reddit',
                icon: 'badges.channel-reddit-subreddit',
                placeholder: 'https://reddit.com/r/holdmycatnip, r/holdmycatnip, u/spez'
            },
            {
                name: 'Telegram Channel',
//                name: 'Telegram Channel or Group',
                id: 'telegram',
                icon: 'badges.channel-telegram-channel',
                placeholder: 'https://t.me/michalsharksvobodnykanal, @michalsharksvobodnykanal'
            },
            {
                name: 'TikTok User',
                id: 'tiktok',
                icon: 'badges.channel-tik-tok-user',
                placeholder: 'https://www.tiktok.com/@tvmarkiza, @tvmarkiza'
            },
            {
                name: 'VKontakte User, Page or Community',
                id: 'vkontakte',
                icon: 'badges.channel-vkontakte-community',
                placeholder: 'https://vk.com/necenzurovane, @necenzurovane'
            },
            {
                name: 'Web Feed',
                id: 'web-feeds',
                icon: 'badges.channel-web-feed',
                placeholder: 'https://dennikn.sk/feed'
            },
            {
                name: 'X User',
                id: 'x',
                icon: 'badges.channel-x-user',
                placeholder: 'https://x.com/nasa, @nasa'
            },
            {
                name: 'Youtube Channel',
                id: 'youtube',
                icon: 'badges.channel-youtube-channel',
                placeholder: 'https://www.youtube.com/channel/wellstuffedbeaver, @wellstuffedbeaver'
            },
            {
                name: '2Ch Board',
                id: '2ch',
                icon: 'badges.channel-2ch-board',
                placeholder: 'https://2ch.hk/pok/'
            },
            {
                name: '4Chan Board',
                id: '4chan',
                icon: 'badges.channel-4chan-board',
                placeholder: 'https://boards.4chan.org/vp'
            }
        ],

        showListsInput: false,

        feedsDiscovered: false,
        feedsDiscovering: false,
        feedsSelected: []
    }),

    inputs: () => ({
        step: 'type',
        type: null,
        value: null,
        lists: [],
        listsSearch: ''
    }),

    submitRequest() {
        if (this.inputs.type.id == 'web-feeds' && ! this.feedsDiscovered) {
            return this.discoverFeeds()
        }

        trackEvent('channels', 'new-monitoring-request-saved', this.inputs.type.id)

        return api.route('me channels monitoring-requests store')
            .json({
                type: this.inputs.type.id,
                value: this.inputs.type.id == 'web-feeds' ? this.feedsSelected.join(',') : this.inputs.value,
                lists: this.inputs.lists
            })
            .post()
            .error(400, err => {
                this.inputs.step = 'error'
                this.errors = {
                    value: err.json.error
                }
            })
    },

    async onResponse(res) {
        useMyNotificationsStore().pushToast({
            title: 'Adding your channel...',
            body: 'This might take a few minutes, feel free to continue using the app.',
            expires: 10
        })

        useChannelsMyChannelsMonitoringRequestsStore().reload()

        this.inputs.step = 'done'
    },

    actions: {
        open() {
            this.reset()

            useModal().show('channels-library-add-channel')
        },

        close() {
            useModal().hide('channels-library-add-channel')
        },

        discoverFeeds() {
            if (this.feedsDiscovering) return

            this.feedsDiscovering = true

            Promise.all(this.inputs.value.split(',').map(
                url => api.route('services feed-discovery')
                    .json({ url: url.trim() })
                    .post()
                    .internalError(() => [])
                    .json()
            )).then(results => {
                console.log(results)

                this.feedsDiscovered = results.flat()
                this.feedsDiscovering = false
                this.submitting = false
            })
        },

        selectAllFeeds() {
            this.feedsSelected = this.feedsSelected.length != this.feedsDiscovered.length
                ? this.feedsDiscovered.map(f => f.url) : []
        },

        resetFeedsDiscovery() {
            this.inputs.value = ''

            this.feedsDiscovered = false
            this.feedsDiscovering = false
            this.feedsSelected = []
        }
    }
})

export default useChannelsModalsLibraryAddChannelStore
