import defineConnectionsGraph from '@/stores/connections/connections-graph'
import useQuickSearchStore from '@/stores/me/quick-search'
import { defineStore } from 'pinia'
import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox.js'
import { useRouter } from '@/helpers.js'

export const useConnectionsPage = defineStore({
    id: 'connectionsPage',

    state: () => ({
        graph: defineConnectionsGraph({ id: 'connectionsGraph' })(),
        lightbox: defineStreamMediaLightbox({ id: 'connectionsMediaLightbox', name: 'connectionsMediaLightbox' })
    }),

    getters: {
        title: () => 'Connections Explorer'
    },

    actions: {
        afterEnter(to) {
            this.graph.reset()

            if (to.params.ids) {
                const nodeIDs = to.params.ids.split(',')
                // Wait for the sigma component to be mounted before initializing the graph
                // TODO - find a better way to do this
                setTimeout(() => this.initializeGraph(nodeIDs, to.query?.type), 200)
            }
        },

        chooseChannel() {
            useQuickSearchStore().show({
                families: [ 'channels' ],
                limit: 50,
                onSelect: result => {
                    useRouter().push({ name: 'connections', params: { ids: result.id } })
                }
            })
        },

        initializeGraph(nodeIds, type) {
            this.graph.initialize(type)
            this.graph.inspectNode(nodeIds)
        }
    }
})

export default useConnectionsPage
