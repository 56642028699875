<template>
    <div>
        <stream-toolbar-results :store="store" v-if="store.resultsToolbar">
            <template v-slot:results-actions>
                <slot name="results-actions"></slot>
            </template>
        </stream-toolbar-results>

        <slot name="above-results"></slot>

        <component :is="layoutComponent" :store="store" :stream-width="streamWidth" v-if="store.items.data && store.items.data.length || store.items.pending">
            <template v-slot:item="{ item, layout }">
                <slot name="item" :item="item" :layout="layout">
                </slot>
            </template>

            <template v-slot:placeholder="{ item, layout }">
                <slot name="placeholder" :item="item" :layout="layout"></slot>
            </template>
        </component>

        <div v-else-if="store.items.error">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="frown" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">Ooops, something went wrong!</div>
                <div class="text-center text-sm">
                    <p>Looks like we are experiencing some technical issues (sorry about that).</p>
                    <p>Please try again later, if the problem persists, please <a class="underline" href="mailto:support@gerulata.com">contact us</a> for further assistance.</p>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="search" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">
                    <p>Check your spelling and try again, or try a different set of filters.</p>
                </div>
            </div>
        </div>

        <ui-infinite-loading class="mt-12 mb-8" :identifier="store.lastReloadTime" @infinite="store.loadAdditional($event)" v-if="store.items.data && store.items.data.length"></ui-infinite-loading>

        <Teleport to="#root">
            <stream-lightbox :store="store.mediaLightbox"></stream-lightbox>
        </Teleport>
    </div>
</template>

<script>
import StreamLayoutCards from './layouts/cards'
import StreamLayoutGrid from './layouts/grid'
import StreamLayoutMasonry from './layouts/masonry'
import StreamLayoutImages from './layouts/images'
import StreamLayoutVideos from './layouts/videos'
import StreamLayoutTable from './layouts/table'
import StreamLightbox from './lightbox/lightbox'
import StreamToolbarResults from './toolbar/results'

import debounce from 'just-debounce-it'

export default {
    components: {
        StreamLightbox, StreamToolbarResults
    },

    data: () => ({
        streamWidth: null
    }),

    computed: {
        layoutComponent() {
            if (this.store.layout.component) {
                return this.store.layout.component
            }

            return {
                cards: StreamLayoutCards,
                grid: StreamLayoutGrid,
                masonry: StreamLayoutMasonry,
                images: StreamLayoutImages,
                videos: StreamLayoutVideos,
                table: StreamLayoutTable
            }[this.store.layout.id]
        }
    },

    watch: {
        'store.layoutOptions'() {
            this.store.updateAvailableLayouts(this.streamWidth)
        }
    },

    mounted() {
        window.addEventListener('resize', debounce(() => this.refreshStreamWidth(), 50))

        this.refreshStreamWidth()

        setTimeout(() => this.refreshStreamWidth(), 500)
    },

    methods: {
        refreshStreamWidth() {
            this.store.updateAvailableLayouts(this.streamWidth = this.$el.getBoundingClientRect().width)
        }
    }
}
</script>
