<template>
    <ui-lightbox :store="store">
        <template v-slot:sidebar>
            <div class="flex flex-col h-full">
                <div class="p-4 pt-3 border-b border-gray-200 space-y-3">
                    <div class="px-2 font-medium truncate">
                        <ui-icon name="image" class="mr-0.5 shrink-0"></ui-icon>
                        {{ currentMedia.name || 'Untitled Media' }}
                    </div>

                    <div v-if="currentMedia.camera || currentMedia.width || currentMedia.height || currentMedia.size || currentMedia.format || currentMedia.iso || currentMedia.focalLength || currentMedia.exposureValue || currentMedia.aperture || currentMedia.exposure" class="border border-gray-200 rounded-sm text-gray-900">
                        <div class="p-2 text-sm">
                            <div class="mb-1" v-if="currentMedia.camera">
                                <ui-icon name="camera" class="mr-1"></ui-icon>
                                {{currentMedia.camera}}
                            </div>
                            <div class="flex justify-between" v-if="currentMedia.width || currentMedia.height || currentMedia.size || currentMedia.format">
                                <div v-if="currentMedia.width || currentMedia.height">
                                    <ui-icon name="resolution" class="mr-1"></ui-icon>
                                    {{currentMedia.width}} × {{currentMedia.height}}
                                </div>
                                <div v-if="currentMedia.size">
                                    {{currentMedia.size}}
                                </div>
                                <div v-if="currentMedia.format">
                                    <span class="bg-gray-400 text-white rounded-sm px-1 py-0.5 text-xs">
                                        {{currentMedia.format}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-2 text-xs border-t border-gray-200" v-if="currentMedia.iso || currentMedia.focalLength || currentMedia.exposureValue || currentMedia.aperture || currentMedia.exposure">
                            <div class="flex justify-between">
                                <div v-if="currentMedia.iso">
                                    ISO {{currentMedia.iso}}
                                </div>
                                <div v-if="currentMedia.focalLength">
                                    {{currentMedia.focalLength}} mm
                                </div>
                                <div v-if="currentMedia.exposureValue">
                                    {{currentMedia.exposureValue}} ev
                                </div>
                                <div v-if="currentMedia.aperture">
                                    <span class="italic">ƒ</span>/{{currentMedia.aperture}}
                                </div>
                                <div v-if="currentMedia.exposure">
                                    {{currentMedia.exposure}} s
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border border-gray-200 rounded-sm text-gray-700" v-if="currentMedia.credit || currentMedia.author">
                        <div class="p-2 text-sm flex items-center space-x-2">
                            <div>&copy;</div>
                            <div>
                                <div v-if="currentMedia.credit">{{currentMedia.credit}}</div>
                                <div v-if="currentMedia.author">{{currentMedia.author}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="border border-gray-200 rounded-sm text-gray-900 divide-y divide-gray-200" v-if="store.showRawMetadata">
                        <div class="p-2 text-sm" :key="name" v-for="value, name in currentMedia.metaData">
                            <div class="text-xs leading-4 font-semibold">{{name}}</div>
                            <div class="text-sm break-all">{{value}}</div>
                        </div>
                    </div>

                    <div class="leading-none text-center" v-if="currentMedia && currentMedia.metaData && Object.keys(currentMedia.metaData).length">
                        <a href="#" class="text-blue-500 font-medium text-xs" @click.prevent="store.showRawMetadata = ! store.showRawMetadata">
                            {{ store.showRawMetadata ? 'Show Less' : 'Show More' }}
                        </a>
                    </div>

                    <div class="space-y-1">
                        <router-link v-if="fingerprint" :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[mentions]': `dhash-fingerprint|${fingerprint.id}|${fingerprint.name}` } }" target="_blank" class="block text-blue-600 hover:text-blue-800 px-2">
                            <ui-icon name="search" class="mr-0.5"></ui-icon>
                            Reverse image search
                        </router-link>

                        <a :href="currentMedia.url" target="_blank" class="block text-blue-600 hover:text-blue-800 px-2" v-if="currentMedia.mediaType == 'image'">
                            <ui-icon name="download" class="mr-0.5"></ui-icon>
                            Download image
                        </a>

                        <a href="#" class="block text-blue-600 hover:text-blue-800 px-2" :disabled="! supportsVideoDownload" @click.prevent="downloadVideo" v-if="currentMedia.mediaType == 'video'">
                            <ui-icon name="download" class="mr-0.5"></ui-icon>
                            Download video
                        </a>

                        <a href="#" class="block text-blue-600 hover:text-blue-800 px-2" :disabled="! isLanguageTranslatable(currentMedia.language)" @click.prevent="translate(currentMedia, { id: store.content.id, mediaId: currentMedia.id })" v-if="currentMedia.text">
                            <ui-icon name="translate" class="mr-0.5"></ui-icon>
                            Translate text
                        </a>
                    </div>
                </div>

                <div class="bg-gray-50 rounded-sm px-2 py-1 mt-3 mx-4" v-if="currentMedia.translation?.isLoading || currentMedia.translation?.text">
                    <div class="text-xs text-gray-800 font-semibold italic mb-1">
                        <ui-icon name="translate"></ui-icon>
                        Translated text
                    </div>

                    <div v-if="currentMedia.translation?.isLoading" class="flex items-center justify-center py-4">
                        <ui-spinner></ui-spinner>
                    </div>
                    <div class="whitespace-pre-line break-words" v-else v-html="currentMedia.translation?.text"></div>
                </div>

                <div class="p-4 pt-3 border-b border-gray-200" v-if="mediaText" v-html="mediaText"></div>

                <div v-if="currentMedia.mediaType == 'image'" class="p-4 mt-auto space-y-1">
                    <a class="block text-blue-600 hover:text-blue-800 px-2" :href="reverseImageSearchGoogleUrl" target="_blank" rel="noreferrer noopener">
                        <ui-icon name="arrow-right-circle" class="mr-0.5"></ui-icon>
                        Reverse image search via Google
                    </a>

                    <a class="block text-blue-600 hover:text-blue-800 px-2" :href="reverseImageSearchYandexUrl" target="_blank" rel="noreferrer noopener">
                        <ui-icon name="arrow-right-circle" class="mr-0.5"></ui-icon>
                        Reverse image search via Yandex
                    </a>
                </div>

                <div v-if="currentMedia.firstSeenAt" class="p-4 border-t border-gray-200">
                    <div class="text-sm text-gray-900 px-2">
                        <ui-icon name="eye" class="mr-1"></ui-icon>
                        {{ $dateTime(currentMedia.firstSeenAt) }}
                    </div>
                </div>
            </div>
        </template>
    </ui-lightbox>
</template>

<script>
import UiLightbox from '@/components/ui/lightbox'

import useContentModalsVideoDownloadStore from '@/stores/content/modals/video-download'

import { isLanguageTranslatable, translate } from '@/helpers/translations'
import { downloadUrl } from '@/helpers/download'

export default {
    components: { UiLightbox },

    computed: {
        currentMedia() { return this.store.currentMedia },

        fingerprint() {
            return this.currentMedia?.features?.find(f => f.type == 'dhash-fingerprint')
        },

        reverseImageSearchGoogleUrl() {
            return `https://lens.google.com/uploadbyurl?url=${this.currentMedia.url}`
        },

        reverseImageSearchYandexUrl() {
            return `https://yandex.com/images/search?rpt=imageview&url=${this.currentMedia.url}`
        },

        mediaText() {
            return this.currentMedia?.text?.replaceAll('\n', '<br>')
        },

        supportsVideoDownload() {
            return this.currentMedia?.url
                || [ 'facebook-post', 'telegram-channel-post', 'x-post', 'vkontakte-post', 'youtube-video' ].includes(this.store.content?.type)
        }
    },

    methods: {
        isLanguageTranslatable, translate,

        downloadVideo() {
            if (this.currentMedia?.key) {
                downloadUrl(this.currentMedia?.src)
            } else {
                useContentModalsVideoDownloadStore().open(this.store.content, this.currentMedia)
            }
        }
    }
}
</script>
