import useMyAnalysesStore from '@/stores/me/analyses'
import useMyNotificationsStore from '@/stores/me/notifications'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import hasDatasetConcern from './concerns/has-dataset-concern'

import api from '@/api'
import safeColors from '@/helpers/safe-colors'
import { date, dateTime } from '@/helpers/datetime'
import { downloadBlob } from '@/helpers/download'
import number from '@/helpers/number'
import searchFilters from '@/helpers/search-filters'

import { defineStore } from 'pinia'
import debounce from 'just-debounce-it'
import { shallowRef } from 'vue'

export const defineContentTopPlatformsAnalysisStore = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            analysis: null,

            id: null,
            name: 'Untitled analysis',
            type: 'content-top-platforms',

            series: [],
            styles: {},

            date: { type: 'past', date: { past: 1, unit: 'years' }, },
            granularity: 'day',

            isTakingTooLong: false,

            isLoading: false,
            loadingPromise: null,
            abortLoading: null,

            isDirty: false,
            isSaving: false,

            analysisSeries: [],
            seriesGeneratedAt: null,

            seriesOptions: {
                limit: 10,
                hasColors: false,
                hasLabels: true
            },

            styleOptions: [
                {
                    name: 'Type', id: 'analysis.type', type: 'options', icon: 'activity',
                    options: [
                        { name: 'Line Chart', id: 'line' },
                        { name: 'Spline Chart', id: 'spline' },
                        { name: 'Bar Chart', id: 'column' }
                    ]
                },
                {
                    name: 'Markers', id: 'analysis.markers', type: 'options', icon: 'git-commit',
                    options: [
                        { name: 'Auto', id: 'auto' },
                        { name: 'Show', id: 'show' },
                        { name: 'Hide', id: 'hide' }
                    ]
                },
                { name: 'Y-axis', id: 'analysis.y-axis', type: 'toggle' },
                { name: 'X-axis', id: 'analysis.x-axis', type: 'toggle' }
            ],

            styleDefaults: {
                'analysis.type': 'column',
                'analysis.markers': 'auto',
                'analysis.y-axis': 'enabled',
                'analysis.x-axis': 'enabled'
            },

            table: {
                limit: settings.tableOptions?.limit,
                sorting: { column: 'interactions', direction: 'desc' }
            },

            exportable: { chart: true, data: true },
            printable: true,

            passive: settings.passive || false,

            analysisRef: null,
            taskId: null,

            metrics: [
                { id: 'count', icon: 'hash', name: 'Count', chartMetric: 'count' },
                { id: 'interactions', icon: 'interactions', name: 'Interactions', chartMetric: 'interactions' },
                null,
                { id: 'reactions', icon: 'thumbs-up', name: 'Reactions', chartMetric: 'reactions' },
                { id: 'comments', icon: 'comment', name: 'Comments', chartMetric: 'comments' },
                { id: 'shares', icon: 'share', name: 'Shares', chartMetric: 'shares' },
                { id: 'views', icon: 'eye', name: 'Views', chartMetric: 'views' }
            ]
        }),

        getters: {
            analysisOptions: store => ({
                boost: { useGPUTranslations: true },
                chart: {
                    type: store.effectiveStyles['analysis.type'],
                    resetZoomButton: { position: { x: 0, y: 0 } },
                    spacing: [6, 12, 6, 12],
                    zoomType: 'x'
                },
                credits: { enabled: false },
                exporting: {
                    chartOptions: {
                        chart: {
                            spacing: [40, 40, 40, 40]
                        }
                    },
                    buttons: {
                        contextButton: { enabled: false }
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        borderWidth: 0,
                        groupPadding: 0.1,
                        fillOpacity: 1,
                        marker: { enabled: false },
                        pointPadding: 0,
                        stacking: true
                    },
                    series: {
                        animation: ! settings.passive || true,
                        connectNulls: false,
                        dataLabels: { enabled: false },
                        enableMouseTracking: true,
                        marker: {
                            enabled: store.effectiveStyles['analysis.markers'] == 'show',
                            enabledThreshold: store.effectiveStyles['analysis.markers'] == 'auto' ? 4 : 0,
                            fillColor: '#fff',
                            lineColor: null,
                            lineWidth: 2,
                            radius: 4,
                            symbol: 'circle'
                        },
                        point: {
                            events: {
                                click: function () { window.open(this.options.url, '_blank') }
                            }
                        },
                        states: {
                            hover: { lineWidthPlus: 0 }
                        },
                        turboThreshold: 2500
                    }
                },
                series: store.analysisSeries.map(series => ({
                    name: series.label,
                    color: series.color,
                    data: series.values,
                    visible: series.visible,
                    yAxis: 'interactions'
                })),
                title: {
                    style: {
                        color: 'rgb(44, 64, 76)', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500'
                    },
                    text: store.name
                },
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    hideDelay: 100,
                    padding: 8,
                    headerFormat: '',
                    pointFormatter: function () { return store.granularity === 'hour' ? dateTime(this.x) : date(this.x) + `<br>${this.series.name}<br><strong>${this.y}</strong>` },
                    shadow: false,
                    style: { color: '#fff', textAlign: 'center' }
                },
                xAxis: {
                    title: 'Time',
                    type: 'datetime',
                    labels: {
                        y: 30,
                        align: 'center',
                        enabled: store.effectiveStyles['analysis.x-axis'] == 'enabled' ? (settings.xAxisLabels ?? true) : false,
                        rotation: 45,
                        style: { color: '#b8c3c9' }
                    }
                },
                yAxis: [{
                    gridLineColor: '#e0e5e8',
                    id: 'interactions',
                    labels: {
                        align: 'left',
                        enabled: store.effectiveStyles['analysis.y-axis'] == 'enabled',
                        padding: 0,
                        style: { color: '#b8c3c9' },
                        x: 1,
                        y: 14,
                        zIndex: 1
                    },
                    tickPixelInterval: 50,
                    title: { text: '' }
                }]
            }),

            effectiveStyles: store => ({
                ...store.styleDefaults,
                ...store.styles
            }),

            analysisConfiguration: store => ({
                series: store.series.map(series => ({
                    color: series.color,
                    datasetId: series.datasetId,
                    datasetType: series.datasetType,
                    datasetFilters: series.datasetFilters,
                    label: series.label,
                    meta: series.meta
                })),
                styles: store.effectiveStyles,
                date: store.date,
                granularity: store.granularity
            }),

            hasValues: store => store.analysisSeries.some(series => series.values && series.values.length),
            isRenderable: store => store.analysisSeries.some(series => series.values && series.values.length > 1),

            tableOptions: store => ({
                headers: store.tableColumns.filter(c => ! c.hidden),
                limit: store.table.limit,
                rows: store.tableRows,
                sorting: false,
                footers: [{
                    id: 'summary',
                    type: 'summary',
                    columns: store.tableColumns.filter(c => ! c.hidden).map(column => {
                        if (column.id === 'name') return { id: column.id, value: 'Summary', style: ['py-4', 'px-4', 'font-semibold', 'text-gray-900'] }
                        if (! column.summable) return { id: column.id, value: null }

                        return {
                            id: column.id,
                            style: ['text-right', 'py-4', 'px-4', 'font-semibold', 'text-gray-900'],
                            value: store.tableRows.map(row => row.columns.find(c => c.id === column.id).value).reduce((a, b) => a + b, 0),
                            formatter: value => number(value)
                        }
                    })
                }]
            }),

            tableColumns: store => [
                { id: 'color', type: 'color', title: null, sortable: false, additionalStyle: [ 'w-8' ], hidden: store.effectiveStyles['widget'] },
                { id: 'badge', title: null, sortable: false, additionalStyle: [ 'w-8' ] },
                { id: 'platform', title: 'Platform', sortable: false },
                { id: 'content', title: 'Content', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24' ], hidden: store.effectiveStyles['widget'] && store.effectiveStyles['widget'].w < 2 },
                { id: 'interactions', title: 'Interactions', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24' ] },
                { id: 'reactions', title: 'Reactions', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24' ], hidden: store.effectiveStyles['widget'] },
                { id: 'comments', title: 'Comments', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24' ], hidden: store.effectiveStyles['widget'] },
                { id: 'shares', title: 'Shares', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24' ], hidden: store.effectiveStyles['widget'] },
                { id: 'views', title: 'Views', additionalContentStyle: ['flex', 'justify-end', 'space-x-2'], summable: true, sortable: true, additionalStyle: [ 'w-24' ], hidden: store.effectiveStyles['widget'] }
            ],

            tableRows: store => {
                let rows = store.analysisSeries.map(series => ({
                    id: series.name,
                    enabled: series.visible,
                    columns: [
                        {
                            id: 'color',
                            type: 'color',
                            click: () => series.visible = ! series.visible,
                            value: series.color,
                            style: ['py-1', 'w-6', 'pl-4'],
                            additionalContentStyle: ['h-3 w-3'],
                            hidden: store.effectiveStyles['widget']
                        },
                        {
                            id: 'badge',
                            type: 'icon',
                            click: () => series.visible = ! series.visible,
                            style: ['py-1', 'text-center'],
                            value: `badges.channel-${series.badge}`
                        },
                        {
                            id: 'label',
                            value: series.label,
                            click: () => series.visible = ! series.visible,
                            mouseover: () => { series.borderWidth = 2 },
                            mouseout: () => { series.borderWidth = 0 },
                            additionalStyle: ['w-full']
                        },
                        {
                            id: 'content',
                            value: series.content,
                            progress: true,
                            additionalStyle: ['text-right'],
                            formatter: (value) => number(value),
                            hidden: store.effectiveStyles['widget'] && store.effectiveStyles['widget'].w < 2
                        },
                        {
                            id: 'interactions',
                            value: series.interactions,
                            progress: true,
                            additionalStyle: ['text-right'],
                            formatter: (value) => number(value)
                        },
                        {
                            id: 'reactions',
                            value: series.reactions,
                            progress: true,
                            additionalStyle: ['text-right'],
                            formatter: (value) => number(value),
                            hidden: store.effectiveStyles['widget']
                        },
                        {
                            id: 'comments',
                            value: series.comments,
                            progress: true,
                            additionalStyle: ['text-right'],
                            formatter: (value) => number(value),
                            hidden: store.effectiveStyles['widget']
                        },
                        {
                            id: 'shares',
                            value: series.shares,
                            progress: true,
                            additionalStyle: ['text-right'],
                            formatter: (value) => number(value),
                            hidden: store.effectiveStyles['widget']
                        },
                        {
                            id: 'views',
                            value: series.views,
                            progress: true,
                            additionalStyle: ['text-right'],
                            formatter: (value) => number(value),
                            hidden: store.effectiveStyles['widget']
                        }
                    ].filter(c => ! c.hidden)
                }))

                return store.effectiveStyles['widget'] ? rows.slice(0, 5 * store.effectiveStyles['widget'].h - 3) : rows
            },

            selectedMetric: store => {
                return store.metrics.find(m => m && m.id == (store.series[0].meta.metric || 'interactions'))
            }
        },

        actions: {
            async initialize(analysis) {
                this.$reset()

                this.analysis = analysis

                this.id = analysis.id
                this.name = analysis.name

                this.date = analysis.configuration.date || this.date
                this.granularity = analysis.configuration.granularity || this.granularity

                this.series = analysis.configuration.series || this.series
                this.styles = analysis.configuration.styles || this.styles

                this.loadSeries()
            },

            async loadSeries() {
                if (this.loadingPromise) this.abortLoading.abort()

                this.isLoading = true

                setTimeout(() => {
                    if (this.isLoading) {
                        this.isTakingTooLong = true
                    }
                }, 5000)

                return this.loadingPromise = new Promise((accept, reject) => {
                    api.route('me analyses series')
                        .json({
                            type: this.type,
                            configuration: this.analysisConfiguration,
                            id: this.analysis?.id
                        })
                        .signal(this.abortLoading = new AbortController())
                        .post()
                        .error(422, () => {
                            this.isLoading = false
                        })
                        .json(response => {
                            this.analysisSeries = response.data?.map((series, i) => ({
                                ...series,
                                badge: this.resolveBadge(series),
                                label: this.resolveLabel(series),
                                // color: safeColors.data().safeColors[i] ?? '#' + Math.floor(Math.random() * 16777215).toString(16),
                                color: this.resolveColor(series),
                                content: series.values.reduce((a, b) => a + Number(b.count), 0),
                                interactions: series.values.reduce((a, b) => a + Number(b.interactions), 0),
                                reactions: series.values.reduce((a, b) => a + Number(b.reactions), 0),
                                comments: series.values.reduce((a, b) => a + Number(b.comments), 0),
                                shares: series.values.reduce((a, b) => a + Number(b.shares), 0),
                                views: series.values.reduce((a, b) => a + Number(b.views), 0),
                                visible: i < 10,
                                values: series.values.map(point => ({
                                    x: Date.parse(point.date),
                                    y: Number(point[this.selectedMetric.chartMetric]),
                                    count: Number(point.count),
                                    interactions: Number(point.interactions),
                                    reactions: Number(point.reactions),
                                    comments: Number(point.comments),
                                    shares: Number(point.shares),
                                    views: Number(point.views),
                                    url: point.url
                                }))
                            })) || []
                            this.seriesGeneratedAt = response.preparedAt ? new Date(response.preparedAt) : null

                            if (response.taskId) {
                                useMyNotificationsStore().onTaskCompletion(response.taskId, task => {
                                    this.analysisSeries = task.payload.data.map((series, i) => ({
                                        ...series,
                                        badge: this.resolveBadge(series),
                                        label: this.resolveLabel(series),
                                        // color: safeColors.data().safeColors[i] ?? '#' + Math.floor(Math.random() * 16777215).toString(16),
                                        color: this.resolveColor(series),
                                        content: series.values.reduce((a, b) => a + Number(b.count), 0),
                                        interactions: series.values.reduce((a, b) => a + Number(b.interactions), 0),
                                        reactions: series.values.reduce((a, b) => a + Number(b.reactions), 0),
                                        comments: series.values.reduce((a, b) => a + Number(b.comments), 0),
                                        shares: series.values.reduce((a, b) => a + Number(b.shares), 0),
                                        views: series.values.reduce((a, b) => a + Number(b.views), 0),
                                        visible: i < 10,
                                        values: series.values.map(point => ({
                                            x: Date.parse(point.date),
                                            y: Number(point[this.selectedMetric.chartMetric]),
                                            count: Number(point.count),
                                            interactions: Number(point.interactions),
                                            reactions: Number(point.reactions),
                                            comments: Number(point.comments),
                                            shares: Number(point.shares),
                                            views: Number(point.views),
                                            url: point.url
                                        }))
                                    }))

                                    this.isLoading = this.isTakingTooLong = false
                                    this.taskId = null

                                    accept(this.analysisSeries)
                                })

                                this.taskId = response.taskId
                            } else {
                                this.isLoading = this.isTakingTooLong = false

                                accept(this.analysisSeries)
                            }
                        })
                })
            },

            loadSeriesDebounced: debounce(function () {
                this.loadSeries()
            }, 500),

            addSeries(data) {
                this.series.push({
                    label: data.label,
                    color: data.color,
                    datasetType: data.datasetType,
                    datasetId: data.datasetId,
                    meta: data.meta || { metric: 'interactions' },
                    values: data.values || []
                })

                this.isDirty = true
                this.loadSeries()
            },

            updateSeries(series, data) {
                Object.assign(series, {
                    label: data.label || series.label,
                    color: data.color || series.color,
                    datasetType: data.datasetType || series.datasetType,
                    datasetId: data.datasetId || series.datasetId,
                    meta: data.meta || series.meta,
                    values: data.values || series.values
                })

                this.isDirty = true
                this.loadSeries()
            },

            deleteSeries(series) {
                const index = this.series.indexOf(series)

                if (index >= 0) {
                    this.series.splice(index, 1)

                    this.isDirty = true
                    this.loadSeries()
                }
            },

            isValidSeries(data) {
                return data.label && data.datasetType && data.datasetId
            },

            async fromQuery(query) {
                if (query.date) this.date = searchFilters().unserializeFrom('uri', 'date', query.date)

                await useMyPerspectivesStore().initialize()

                let perspective = useMyPerspectivesStore().find(query['perspective'])

                if (perspective) {
                    this.addSeries({
                        label: perspective.name,
                        color: safeColors.data().safeColors[0],
                        datasetType: 'perspective',
                        datasetId: perspective.id,
                        meta: { metric: 'interactions' }
                    })
                }
            },

            setDate(date) {
                this.date = date
                this.isDirty = true

                this.loadSeries()
            },

            setGranularity(granularity) {
                this.granularity = granularity
                this.isDirty = true

                this.loadSeries()
            },

            setStyle(name, value) {
                this.styles[name] = value
                this.isDirty = true
            },

            setAnalysisRef(analysis) {
                this.analysisRef = shallowRef(analysis)
            },

            setMetric(metric) {
                this.series[0].meta = { metric }
                this.isDirty = true

                this.loadSeries()
            },

            print() {
                this.analysisRef.print()
            },

            exportChart(options) {
                return this.analysisRef.export(options)
            },

            export(format) {
                let filename = `${this.name || 'Untitled Analyses'}.${format}`

                let toast = useMyNotificationsStore().pushToast({
                    title: 'Exporting your analysis...',
                    body: 'This might take a few seconds, your download will begin shortly.',
                    expires: 10
                })

                api.route('me analyses export')
                    .post({
                        type: this.type,
                        configuration: this.analysisConfiguration,
                        filename
                    })
                    .blob(res => {
                        downloadBlob(res, filename, format)
                        useMyNotificationsStore().dismissToast(toast)
                    })
            },

            save(notify = false) {
                this.isSaving = true

                return api.route(this.id ? 'me analyses update' : 'me analyses store', { id: this.id })
                    .json({
                        _method: this.id ? 'put' : 'post',
                        type: this.type,
                        name: this.name,
                        configuration: this.analysisConfiguration,
                        dependencies: this.resolveDependencies(),
                        notify: notify
                    })
                    .post()
                    .json(response => {
                        this.id = response.data.id
                    })
                    .finally(() => {
                        return useMyAnalysesStore().reload().then(() => {
                            this.isSaving = this.isDirty = false
                        })
                    })
            },

            resolveDependencies() {
                return {
                    'lists': this.series.filter(s => s.datasetType == 'list').map(s => s.datasetId),
                    'perspectives': this.series.filter(s => s.datasetType == 'perspective').map(s => s.datasetId),
                    'topics': this.series.filter(s => s.datasetType == 'topic').map(s => s.datasetId)
                }
            },

            abort() {
                if (this.abortLoading) this.abortLoading.abort()
            },

            resolveLabel(series) {
                return {
                    'facebook': 'Facebook',
                    'instagram': 'Instagram',
                    'reddit': 'Reddit',
                    'telegram': 'Telegram',
                    'tiktok': 'TikTok',
                    'vkontakte': 'VKontakte',
                    'web-feeds': 'Web Feeds',
                    'web-sites': 'Web Sites',
                    'x': 'X',
                    'youtube': 'YouTube',
                    '2ch': '2Ch',
                    '4chan': '4Chan'
                }[series.platform]
            },

            resolveBadge(series) {
                return {
                    'facebook': 'facebook-page',
                    'instagram': 'instagram-profile',
                    'reddit': 'reddit-subreddit',
                    'telegram': 'telegram-channel',
                    'tik-tok': 'tik-tok-user',
                    'x': 'x-user',
                    'vkontakte': 'vkontakte-community',
                    'web-feeds': 'web-feed',
                    'web-sites': 'web-site',
                    'youtube': 'youtube-channel',
                    '2ch': '2ch-board',
                    '4chan': '4chan-board'
                }[series.platform]
            },

            resolveColor(series) {
                return {
                    'facebook': '#4267B2',
                    'instagram': '#C13584',
                    'reddit': '#FF4500',
                    'telegram': '#4798BB',
                    'tiktok': '#FF0050',
                    'vkontakte': '#CD0000',
                    'web-feeds': '#F49E28',
                    'web-sites': '#12D31F',
                    'x': '#01C5D3',
                    'youtube': '#F14539',
                    '2ch': '#45C159',
                    '4chan': '#45C159'
                }[series.platform]
            },

            ...hasDatasetConcern.actions
        }
    })
}

const useAnalysisAnalysesContentTopPlatformsStore = defineContentTopPlatformsAnalysisStore({ id: 'analysisAnalysesContentTopPlatforms' })

export default useAnalysisAnalysesContentTopPlatformsStore
