<template>
    <node-view-wrapper as="span" v-click-away="closeDropdown">
        <span class="relative h-full">
            <span
                @click="toggleDropdown"
                v-tooltip="topic.query"
                class="relative mx-0.5 px-1.5 py-0.5 border border-gray-300 bg-blue-50 font-medium text-blue-600 rounded-sm cursor-pointer"
                ref="content"
            >
                {{ topic.name }}
            </span>

            <Teleport to="#root">
                <div :style="dropdownStyle" v-if="showDropdown" class="rounded py-0.5 drop-shadow-lg bg-white bg-opacity-95 backdrop-blur ring-1 ring-black ring-opacity-5 text-left">
                    <a href="#" v-if="extension.options.allowEditingTopics" @click.prevent="editTopic" class="flex items-center my-0.5 mx-1 px-3 rounded leading-5 focus:outline-none transition duration-150 ease-in-out h-7 text-sm text-gray-900 hover:bg-blue-400 hover:text-white">Edit Topic</a>
                    <a href="#" v-if="extension.options.allowUnwrappingTopics" @click.prevent="unwrapTopic" class="flex items-center my-0.5 mx-1 px-3 rounded leading-5 focus:outline-none transition duration-150 ease-in-out h-7 text-sm text-gray-900 hover:bg-blue-400 hover:text-white">Unwrap Topic</a>
                </div>
            </Teleport>
        </span>
    </node-view-wrapper>
</template>

<script>
import useMyTopicsStore from '@/stores/me/topics'
import useContentModalsTopicEditStore from '@/stores/content/modals/topic-edit'

import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
    props: nodeViewProps,

    data: () => ({
        showDropdown: false
    }),

    components: {
        NodeViewWrapper,
    },

    computed: {
        dropdownStyle() {
            const { top, left } = this.$el.getBoundingClientRect()

            return {
                position: 'absolute',
                zIndex: 9999,
                top: `${top + 25}px`,
                left: `${left}px`
            }
        },

        topic() {
            return useMyTopicsStore().find(this.node.attrs.id)
        }
    },

    methods: {
        editTopic() {
            useContentModalsTopicEditStore().open(this.topic).then(res => {
                if (res && res.topic) {
                    useMyTopicsStore().reload()
                }
            })
        },

        unwrapTopic() {
            const pos = this.getPos()
            const query = this.processValue(this.topic.query)

            this.deleteNode()

            this.editor.commands.insertContentAt(pos, query)
        },

        processValue(value) {
            value = value.replaceAll(/\/topic\/([a-z0-9-]+)/ig, (match, id) => {
                const topic = useMyTopicsStore().find(id)

                if (topic) {
                    return `<input-topic data-type="mention" data-id="${topic.id}" data-label="${topic.name}">/${topic.name}</input-topic>`
                }

                return match
            })

            value = value.replaceAll(/\n/g, '<br>')

            return `(${value})`
        },

        toggleDropdown() {
            if (! this.extension.options.allowEditingTopics && ! this.extension.options.allowUnwrappingTopics) { return }

            this.showDropdown = ! this.showDropdown
        },

        closeDropdown() {
            this.showDropdown = false
        }
    },
}
</script>
