import { defineStore } from 'pinia'
import useContentTracking from '@/stores/connections/content-tracking'

export const useContentTrackingPage = defineStore({
    id: 'contentTrackingPage',

    actions: {
        async afterEnter(to) {
            useContentTracking().init(to.params.id)
        }
    }
})

export default useContentTrackingPage
