import textSearch from '@/helpers/text-search'

import { default as Grid } from '@/components/ui/cards/layouts/grid'
import { default as Table } from '@/components/ui/cards/layouts/table'

import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const defineCards = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            source: settings.source || (() => ({ items: [] })),
            paginated: settings.paginated || false,
            filter: settings.filter || ((items, store) => textSearch(store.searchQuery, items, i => i.name)),

            chooseCallback: settings.choose || false,

            layoutOptions: settings.layoutOptions || [
                settings.layout != 'table' ? {
                    id: 'grid',
                    name: 'Grid',
                    icon: 'view-grid',
                    available: true,
                    component: markRaw(Grid),
                    settings: { showCharts: true }
                } : undefined,
                settings.layout != 'grid' ? {
                    id: 'table',
                    name: 'Table',
                    icon: 'menu',
                    available: true,
                    component: markRaw(Table),
                    settings: { showCharts: true }
                } : undefined
            ].filter(Boolean),

            sortingOptions: settings.sortingOptions || [
                { id: 'default', name: 'Default', icon: '', direction: 'asc', default: true, apply: items => items }
            ],

            selectedSortingOption: null,
            selectedLayoutOption: null,

            rows: settings.rows,

            searchable: settings.searchable ?? false,
            searchQuery: '',

            params: {},

            ...(settings.state ? settings.state() : [])
        }),

        getters: {
            availableLayoutOptions(store) {
                return store.layoutOptions.filter(option => option.available !== false)
            },

            availableSortingOptions(store) {
                return store.sortingOptions.filter(option => {
                    return option.available === undefined || option.available === true || option.available(store)
                })
            },

            items(store) {
                let items = [ ...this.source().items ]

                items = this.filter(items, store)

                items = this.sorting.apply ? this.sorting.apply(items) : items
                items = this.sorting.apply && this.sorting.direction === 'desc' ? items.reverse() : items

                return items
            },

            sorting(store) {
                let option = store.availableSortingOptions.find(o => o.id && o.id == store.selectedSortingOption)

                if (! option) option = store.availableSortingOptions.find(o => o.default) ?? store.availableSortingOptions[0]

                return option
            },

            layout(store) {
                let layout = store.layoutOptions.find(o => o.id == store.selectedLayoutOption)

                return layout && layout.available ? layout : store.layoutOptions.find(o => o.available !== false)
            },

            layoutSettings() {
                return this.layout.settings || {}
            },

            isInitialized() {
                return this.source().isInitialized
            },

            isLoading() {
                return this.source().isLoading
            },

            isEmpty() {
                return ! this.source().items.length
            },

            ...(settings.getters || [])
        },

        actions: {
            initialize(params) {
                this.source().initialize(this.params = settings.mapParams ? settings.mapParams(params) : params)
            },

            setSorting(option) {
                if (! this.sortingOptions.find(o => o.id == option)) return

                this.selectedSortingOption = option

                if (this.onSortingChange) this.onSortingChange()
            },

            setLayout(layout, callback = null) {
                this.selectedLayoutOption = layout

                if (this.onLayoutChange) this.onLayoutChange()
            },

            refreshLayouts(width) {
                this.layoutOptions.forEach(layout => {
                    layout.available = ! layout.minWidth || width >= layout.minWidth
                })
            },

            search(query) {
                this.searchQuery = query

                this.source()?.search?.(query)
            },

            choose(item) {
                this.chooseCallback(item, this)
            },

            ...(settings.actions || [])
        }
    })
}

export default defineCards

export const useDefaultCardsStore = defineCards({
    id: 'default-cards'
})
