import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyCuratedListsStore from '@/stores/me/curated-lists'

import defineCards from '@/stores/reusable/cards'

import { asyncResource, channels } from '@/api'
import { sortAlphabetically } from '@/helpers/sorting'

import { defineStore } from 'pinia'

export const useIndexCardsListsListStore = defineCards({
    id: 'channelsIndexCardsListsList',

    source: defineStore({
        id: 'channelsIndexCardsListsListSource',
        
        state: () => ({
            params: {},
            paginated: true,
            
            resource: asyncResource({
                method: 'post',
                request: (api, store, payload) => channels()
                    .filters({ list: store.params.id })
                    .query(payload)
                    .sorting('name-asc')
                    .toRequest()
            })
        }),
        
        getters: {
            items(store) {
                return store.resource.data || []
            },

            isLoading(store) {
                return store.resource.isFetchingFirst
            }
        },

        actions: {
            initialize(params) {
                this.params = params
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            reload() {
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            async loadMore(infiniteScroll) {
                let items = await this.resource.fetchNext(this)

                items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
            }
        }
    }),
    layout: 'table',

    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        }
    ],

    getters: {
        list: store => useMyChannelListsStore().find(store.params.id) || useMyCuratedListsStore().findList(store.params.id)
    }
})

export default useIndexCardsListsListStore
