<template>
    <div class="relative">
        <input :value="store.searchQuery" @input="event => store.search(event.target.value)" type="text" class="bg-transparent border border-gray-300 focus:border-gray-400 text-gray-700 focus:text-gray-900 rounded-md flex items-center justify-center text-sm h-8 px-8" placeholder="Search...">
        <div class="absolute top-0 left-0 h-8 flex items-center px-3 text-gray-800">
            <ui-icon name="search"></ui-icon>
        </div>
        <div class="absolute top-0 right-0 h-8 flex items-center px-3 text-gray-800" v-if="store.searchQuery">
            <a href="#" @click.prevent="store.search()">
                <ui-icon name="x"></ui-icon>
            </a>
        </div>
    </div>
</template>
