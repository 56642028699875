<template>
    <div class="relative w-full h-full bg-gray-75" ref="pageContainer">
        <template v-if="$page.graph.inspectedNodeIds.length">
            <div class="w-72 absolute top-4 left-4 z-20">
                <div class="bg-white w-full overflow-hidden shadow rounded py-2 mb-4">
                    <a href="#" class="flex items-center font-medium px-6 py-2" @click.prevent="toggleControls">
                        <ui-icon name="share-2" class="mr-2"></ui-icon>
                        Explore
                        <ui-icon :name="controls != 'hidden' ? 'chevron-down' : 'chevron-right'" class="ml-auto"></ui-icon>
                    </a>
                    <div class="border-t border-gray-200 mt-2 pt-2" v-if="controls != 'hidden'">
                        <template v-if="controls == 'shown'">
                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'type'">
                                <div class="flex-1">
                                    <div>{{selectedType.name}}</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500">{{selectedType.description}}</div>
                                </div>
                                <ui-icon name="arrow-right-circle" class="shrink-0"></ui-icon>
                            </a>

                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'channels'" v-if="channelsInGraph.length">
                                <div class="flex-1">
                                    <div v-if="$page.graph.shownChannels.length == channelsInGraph.length">Show all Channels</div>
                                    <div v-else-if="$page.graph.shownChannels.length">Show some Channels</div>
                                    <div v-else>Don't show Channels</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500" v-if="$page.graph.shownChannels.length && $page.graph.shownChannels.length != channelsInGraph.length">
                                        {{ shownChannelNames.join(', ') }}
                                    </div>
                                </div>
                                <ui-icon name="arrow-right-circle" class="shrink-0"></ui-icon>
                            </a>

                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'features'" v-if="featuresInGraph.length">
                                <div class="flex-1">
                                    <div v-if="$page.graph.shownFeatures.length == featuresInGraph.length">Show all Features</div>
                                    <div v-else-if="$page.graph.shownFeatures.length">Show some Features</div>
                                    <div v-else>Don't show Features</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500" v-if="$page.graph.shownFeatures.length && $page.graph.shownFeatures.length != featuresInGraph.length">
                                        {{ shownFeatureNames.join(', ') }}
                                    </div>
                                </div>
                                <ui-icon name="arrow-right-circle" class="shrink-0"></ui-icon>
                            </a>
                        </template>

                        <template v-if="controls == 'type'">
                            <a href="#" class="flex items-center space-x-3 font-medium px-6 py-2 hover:text-blue-600 group" :class="{ 'text-blue-600': selectedType.id == type.id }" @click.prevent="selectType(type.id)" :key="type.id" v-for="type in types">
                                <div class="flex-1">
                                    <div>{{type.name}}</div>
                                    <div class="text-xs font-normal group-hover:text-blue-500">{{type.description}}</div>
                                </div>
                                <ui-icon name="check" class="shrink-0" :class="{ 'text-gray-400': selectedType.id != type.id }"></ui-icon>
                            </a>
                        </template>

                        <template v-if="controls == 'channels'">
                            <div class="max-h-96 overflow-y-scroll">
                                <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" :class="{ 'text-blue-600': $page.graph.shownChannels.includes(channel.type) }" @click.prevent="toggleShownChannel(channel.type)" :key="channel.type" v-for="channel in channelsInGraph">
                                    <ui-icon :name="channel.icon" class="mr-2"></ui-icon>
                                    <div>{{channel.name}}</div>
                                    <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! $page.graph.shownChannels.includes(channel.type) }"></ui-icon>
                                </a>
                            </div>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownChannel(true)">
                                Show All
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownChannel(false)">
                                Show None
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'shown'">
                                <ui-icon name="arrow-left" class="mr-2"></ui-icon>
                                Back
                            </a>
                        </template>

                        <template v-if="controls == 'features'">
                            <div class="max-h-96 overflow-y-scroll">
                                <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" :class="{ 'text-blue-600': $page.graph.shownFeatures.includes(feature.type) }" @click.prevent="toggleShownFeature(feature.type)" :key="feature.type" v-for="feature in featuresInGraph">
                                    <ui-icon :name="feature.icon" class="mr-2"></ui-icon>
                                    <div>{{feature.name}}</div>
                                    <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! $page.graph.shownFeatures.includes(feature.type) }"></ui-icon>
                                </a>
                            </div>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownFeature(true)">
                                Show All
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="toggleShownFeature(false)">
                                Show None
                            </a>
                            <a href="#" class="flex items-center font-medium px-6 py-2 hover:text-blue-600 group" @click.prevent="controls = 'shown'">
                                <ui-icon name="arrow-left" class="mr-2"></ui-icon>
                                Back
                            </a>
                        </template>
                    </div>
                </div>
            </div>

            <div class="absolute bottom-4 left-4 z-20 flex shadow rounded bg-white divide-x divide-gray-200">
                <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:bg-gray-50" @click.prevent="$page.graph.zoomOut">
                    <ui-icon name="zoom-out"></ui-icon>
                </a>
                <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:bg-gray-50" @click.prevent="$page.graph.zoomIn">
                    <ui-icon name="zoom-in"></ui-icon>
                </a>
                <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:bg-gray-50" @click.prevent="toggleFullscreen">
                    <ui-icon :name="isFullscreen ? 'minimize-2' : 'maximize-2'"></ui-icon>
                </a>
            </div>

            <div class="w-72 absolute top-4 right-4 z-20 z-9999">
                <NodesSidebar v-if="this.searchQuery || nodes.length" :nodes="nodes" :store="$page.graph" />
            </div>

            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 shadow-sm" v-if="$page.graph.isLoading">
                <div class="bg-white shadow rounded flex items-center px-4 py-2">
                    <ui-spinner type="clip" class="mr-1"></ui-spinner> Updating graph data...
                </div>
            </div>

            <div class="fixed top-96 z-10 flex justify-center w-full" v-if="!$page.graph.isLoading && !$page.graph.nodes.length">
                <ui-icon name="slash" class="pt-1" style="height: 2em; width: 2em"></ui-icon>
                <div class="text-2xl mt-0.5 ml-0.5">There's no data for this graph yet</div>
            </div>

            <div class="absolute bottom-16 left-4 bg-white z-20" style="width: 290px" v-if="$page.graph.nodes.length">
                <div class="flex items-center font-medium px-6 py-2 w-full" @click.prevent="() => { showStats = !showStats }">
                    <ui-icon name="bar-chart-2" class="mr-2"></ui-icon>
                    Stats
                    <ui-icon :name="showStats  ? 'chevron-down' : 'chevron-right'" class="ml-auto"></ui-icon>
                </div>

                <div class="border-t border-gray-200 max-h-40 overflow-y-auto px-6 py-2" v-if="showStats">
                    <div class="mb-2">
                        <div class="font-bold">Nodes</div>
                        <div v-for="(value, key) in stats.classes" :key="key" v-if="stats?.classes">
                            <div class="float-right">{{ value }}</div>
                            <div class="capitalize">{{ key.replace('-', ' ') }}</div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="font-bold">Node types</div>
                        <div v-for="(value, key) in stats.object_types" :key="key" v-if="stats?.object_types">
                            <div class="float-right">{{ value }}</div>
                            <div class="capitalize">{{ key.replace('-', ' ') }}</div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="font-bold">Edges</div>
                        <div v-for="(value, key) in stats.edges" :key="key" v-if="stats?.edges">
                            <div class="float-right">{{ value }}</div>
                            <div class="capitalize">{{ key.replace('-', ' ') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="absolute inset-0 flex items-center justify-center" v-if="$page.graph.isLoading">
                <ui-spinner></ui-spinner>
            </div>

            <div class="w-full h-full flex items-center justify-center" v-else>
                <div class="bg-white border border-gray-200 shadow-sm rounded-md px-8 py-12 flex flex-col items-center text-center space-y-6 w-96">
                    <h1 class="text-xl font-semibold">
                        <ui-icon name="share" class="mr-1"></ui-icon>
                        Connections Explorer
                    </h1>

                    <div>
                        Connections Explorer allows you to explore the network of interconnected Channels in a visual way.
                    </div>

                    <div>
                        Choose a channel to use as a starting point.
                    </div>

                    <ui-button @click="$page.chooseChannel" color="blue" size="lg">
                        Choose a channel
                    </ui-button>
                </div>
            </div>
        </template>

        <ui-async :guards="$page.graph">
            <div class="w-full h-full" id="connections-graph-container"></div>
        </ui-async>
    </div>

    <Teleport to="#root">
        <connections-modals-node-details></connections-modals-node-details>
    </Teleport>
</template>

<script>
import ConnectionsModalsNodeDetails from '@/components/connections/modals/node-details'
import { sidebar_channels, sidebar_features } from "./node_styles.js"
import NodesSidebar from "./nodes-sidebar.vue"


export default {
    components: {
        NodesSidebar,
        ConnectionsModalsNodeDetails
    },

    data: () => ({
        showStats: false,
        controls: 'shown',
        nodesList: 'shown',

        searchQuery: '',
        isFullscreen: false,

        types: [
            { id: 'actor-feature', name: 'Curated Connections', description: 'Channels and features that are related to this Channel in meaningful way.' },
            { id: 'all', name: 'All Connections', description: 'Channels and features that are related to this Channel in any way.' },
            { id: 'upstream', name: 'Upstream Channels', description: 'Channels this channel shares content from.' },
            { id: 'downstream', name: 'Downstream Channels', description: 'Channels sharing content from this Channel.' },
            { id: 'shared-properties', name: 'Channels Sharing Properties', description: 'Channels sharing the same Features.' },
            { id: 'connected-features', name: 'Connected Features', description: 'Features connected to this channel directly or via published content.' }
        ]
    }),

    computed: {
        selectedType() {
            return this.types.find(t => t.id === this.$page.graph.type)
        },

        featuresInGraph() {
            return sidebar_features.filter(f => this.$page.graph.featuresInGraph.includes(f.type))
        },

        channelsInGraph() {
            return sidebar_channels.filter(ch => this.$page.graph.channelsInGraph.includes(ch.type))
        },

        shownChannelNames() {
            return this.$page.graph.shownChannels.map(channel => {
                return sidebar_channels.find(t => t.type === channel).name
            })
        },

        shownFeatureNames() {
            return this.$page.graph.shownFeatures.map(feature => sidebar_features.find(t => t.type === feature).name)
        },

        nodes() {
            return this.$page.graph.nodes.map(n => {
                if (n.attributes.label) {
                    if (n.attributes.class === 'Channel') {
                        return {
                            id: n.key,
                            name: n.attributes.label,
                            type: n.attributes.object_type,
                            icon: sidebar_channels.find(t => t.type === n.attributes.object_type)?.icon || 'badges.feature-image-cluster'
                        }
                    } else if (n.attributes.class === 'Feature') {
                        return {
                            id: n.key,
                            name: n.attributes.label,
                            type: n.attributes.object_type,
                            icon: sidebar_features.find(t => t.type === n.attributes.object_type)?.icon || 'badges.feature-image-cluster'
                        }
                    }
                }
            })
            .filter(n => n)
            .sort((a, b) => a.name.localeCompare(b.name))
        },

        stats() {
            return this.$page.graph.stats
        }
    },

    methods: {
        toggleControls() {
            this.controls = this.controls === 'hidden' ? 'shown' : 'hidden'
        },

        selectType(type) {
            this.$page.graph.setType(type)
            this.controls = 'shown'
        },

        toggleShownChannel(channelType) {
            let shownChannels = [ ...this.$page.graph.shownChannels ]

            if (channelType === true) {
                shownChannels = [ ...this.$page.graph.channelsInGraph ]
            } else if (channelType === false) {
                shownChannels = []
            } else if (channelType) {
                if (shownChannels.includes(channelType)) {
                    shownChannels = shownChannels.filter(t => t !== channelType)
                } else {
                    shownChannels = [ ...shownChannels, channelType ]
                }
            }

            this.$page.graph.setShownChannels(shownChannels)
        },

        toggleShownFeature(featureType) {
            let shownFeatures = [ ...this.$page.graph.shownFeatures ]

            if (featureType === true) {
                shownFeatures = [ ...this.$page.graph.featuresInGraph ]
            } else if (featureType === false) {
                shownFeatures = []
            } else if (featureType) {
                if (shownFeatures.includes(featureType)) {
                    shownFeatures = shownFeatures.filter(t => t !== featureType)
                } else {
                    shownFeatures = [ ...shownFeatures, featureType ]
                }
            }

            this.$page.graph.setShownFeatures(shownFeatures)
        },

        toggleFullscreen() {
            if (this.isFullscreen) {
                document.exitFullscreen().then(() => this.isFullscreen = false)
            } else {
                this.$refs.pageContainer.requestFullscreen().then(() => this.isFullscreen = true)
            }
        }
    }
}
</script>
