<template>
    <search-filter name="Types" :ids="['type', 'replies']" :filters="filters" @opened="reset">
        <template v-slot:applied>
            <div class="flex items-center">
                <div v-if="typeValue.length">
                    <ui-icon :name="typeValue[0].icon" class="mr-1" :class="typeValue[0].iconClass" v-if="typeValue.length === 1 && typeValue[0].icon"></ui-icon>

                    <span class="font-medium" v-if="typeValue.length > 1">
                        {{typeValue.length}} types
                    </span>
                    <span class="font-medium" v-else>
                        {{typeValue.length ? typeValue[0].name : typeValue.name}}
                    </span>
                </div>

                <div class="flex items-center font-medium" v-if="repliesValue == 'include'">
                    <span v-if="typeValue.length" class="ml-1">+ comments</span>
                    <span v-else>All + comments</span>
                </div>

                <div class="flex items-center font-medium" v-if="repliesValue == 'only'">
                    <span v-if="typeValue.length" class="ml-1">only comments</span>
                    <span v-else>Only comments</span>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-96 overflow-y-auto">
                <template v-for="type in types" :key="type.id">
                    <ui-dropdown-link v-if="type.id" :icon="type.icon" checkbox :selected="localTypeValues.includes(type.id)" @click="toggleTypeValue(type)">
                        {{type.name}}
                    </ui-dropdown-link>

                    <ui-dropdown-separator v-else></ui-dropdown-separator>
                </template>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link icon="message-square" submenu submenu-align="left" submenu-width="w-64" ref="repliesDropdownLink">
                    <template v-if="localRepliesValue == 'only'">
                        Only comments...
                    </template>
                    <template v-else-if="localRepliesValue == 'include'">
                        Include comments...
                    </template>
                    <template v-else>
                        Exclude comments...
                    </template>

                    <template v-slot:submenu>
                        <ui-dropdown-link :selected="localRepliesValue == 'exclude'" @click="setRepliesValue('exclude')" keep-open>
                            Exclude comments
                        </ui-dropdown-link>
                        <ui-dropdown-link :selected="localRepliesValue == 'include'" @click="setRepliesValue('include')" keep-open>
                            Include comments
                        </ui-dropdown-link>
                        <ui-dropdown-link :selected="localRepliesValue == 'only'" @click="setRepliesValue('only')" keep-open>
                            Only comments
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="filters.remove('type'); filters.remove('replies'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        types: [
            { name: 'Facebook Post', id: 'facebook-post', icon: 'badges.channel-facebook-page' },
            { name: 'Facebook Group Post', id: 'facebook-group-post', icon: 'badges.channel-facebook-group' },
            { name: 'Instagram Post', id: 'instagram-post', icon: 'badges.channel-instagram-profile' },
            { name: 'Reddit Post', id: 'reddit-post', icon: 'badges.channel-reddit-subreddit' },
            { name: 'Telegram Channel Post', id: 'telegram-channel-post', icon: 'badges.channel-telegram-channel' },
            { name: 'Telegram Message', id: 'telegram-message', icon: 'badges.channel-telegram-group' },
            { name: 'TikTok Video', id: 'tik-tok-video', icon: 'badges.channel-tik-tok-user' },
            { name: 'VKontakte Post', id: 'vkontakte-post', icon: 'badges.channel-vkontakte-community' },
            { name: 'Web Feed Article', id: 'web-feed-article', icon: 'badges.channel-web-feed' },
            { name: 'Web Site Article', id: 'web-site-article', icon: 'badges.channel-web-site' },
            { name: 'X Post', id: 'x-post', icon: 'badges.channel-x-user' },
            { name: 'Youtube Video', id: 'youtube-video', icon: 'badges.channel-youtube-channel' },
            {},
            { name: '2Ch Post', id: '2ch-post', icon: 'badges.channel-2ch-board' },
            { name: '4Chan Post', id: '4chan-post', icon: 'badges.channel-4chan-board' }
        ],

        localTypeValues: [],
        localRepliesValue: false
    }),

    computed: {
        typeValue() {
            return (this.filters.value('type') ?? []).map(type => this.types.find(t => t.id == type))
        },

        repliesValue() {
            return this.filters.value('replies') ?? false
        },
    },

    methods: {
        toggleTypeValue(item) {
            this.localTypeValues.includes(item.id)
                ? this.localTypeValues.splice(this.localTypeValues.findIndex(v => v == item.id), 1)
                : this.localTypeValues.push(item.id)
        },

        setRepliesValue(value) {
            this.localRepliesValue = value

            this.$refs.repliesDropdownLink.showSubmenu = false
        },

        reset() {
            this.localTypeValues = this.filters.value('type') ? [ ...this.filters.value('type') ] : []
            this.localRepliesValue = this.filters.value('replies') ?? 'exclude'
            this.search = ''
        },

        apply() {
            this.filters.set({
                'replies': this.localRepliesValue !== 'exclude' ? this.localRepliesValue : undefined,
                'type': this.localTypeValues.length ? this.localTypeValues : undefined
            })
        }
    }
}
</script>
